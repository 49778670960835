var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-configuration" }, [
    _c("div", { staticClass: "card box-content" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { class: { "col-4": _vm.render_large_chart } }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("Chart Type"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.local_chart.type,
                      expression: "local_chart.type",
                    },
                  ],
                  staticClass: "form-select",
                  class: { "is-invalid": _vm.local_errors.chart_type != "" },
                  attrs: { id: "chart-type-select" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.local_chart,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.changeChartTypeSelection()
                      },
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [_vm._v("Select the chart type...")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "scorecard" } }, [
                    _vm._v("Scorecard"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "pie" } }, [
                    _vm._v("Pie Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "line" } }, [
                    _vm._v("Line Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "cumulative_line" } }, [
                    _vm._v("Cumulative Line Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "bar_line" } }, [
                    _vm._v("Bar-Line Combo Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "column" } }, [
                    _vm._v("Column Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "stacked_column" } }, [
                    _vm._v("Stacked Column Chart"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "100_percent_stacked_column" } },
                    [_vm._v("100% Stacked Column Chart")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "horizontal_bar" } }, [
                    _vm._v("Horizontal Bar Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "stacked_bar" } }, [
                    _vm._v("Stacked Horizontal Bar Chart"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "table" } }, [
                    _vm._v("Table"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "textbox" } }, [
                    _vm._v("Textbox"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.local_errors.chart_type != ""
                ? _c("div", { staticClass: "text-danger invalid-feedback" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.local_errors.chart_type) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.local_chart.type != "textbox"
              ? _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Dimensions"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-xs btn-secondary float-end",
                            attrs: {
                              type: "button",
                              id: "add-new-dimension",
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#add-metric-filter-modal",
                            },
                            on: {
                              click: function ($event) {
                                _vm.add_new_option = "Dimension"
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-plus",
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                title:
                                  "Creating a new Dimension option will add it to the Dimension option dropdown.",
                              },
                            }),
                            _vm._v(
                              " Add New\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("accordion-multi-select", {
                      class: { "is-invalid": _vm.local_errors.group_by != "" },
                      attrs: {
                        id: "dimensions-multi-select",
                        options: _vm.dimension_options,
                        show_hidden_fields: true,
                        max_num_values: _vm.max_values.dimensions,
                        field_name: "dimensions",
                      },
                      on: {
                        input: function ($event) {
                          _vm.local_errors.group_by = ""
                        },
                      },
                      model: {
                        value: _vm.local_chart.group_by,
                        callback: function ($$v) {
                          _vm.$set(_vm.local_chart, "group_by", $$v)
                        },
                        expression: "local_chart.group_by",
                      },
                    }),
                    _vm._v(" "),
                    _vm.local_errors.group_by != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.local_errors.group_by) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.local_chart.type != "textbox"
            ? _c("div", { class: { "col-4": _vm.render_large_chart } }, [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Metrics"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-xs btn-secondary float-end",
                            attrs: {
                              type: "button",
                              id: "add-new-metric",
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#add-metric-filter-modal",
                            },
                            on: {
                              click: function ($event) {
                                _vm.add_new_option = "Metric"
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-plus",
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                title:
                                  "Creating a new Metric option will add it to the Metrics option dropdown.",
                              },
                            }),
                            _vm._v(
                              " Add New\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("accordion-multi-select", {
                      class: { "is-invalid": _vm.local_errors.metrics != "" },
                      attrs: {
                        id: "metrics-multi-select",
                        options: _vm.metric_options,
                        field_name: "metrics",
                        show_hidden_fields: true,
                        max_num_values: _vm.max_metrics,
                      },
                      on: {
                        input: function ($event) {
                          _vm.local_errors.metrics = ""
                        },
                      },
                      model: {
                        value: _vm.local_chart.metrics,
                        callback: function ($$v) {
                          _vm.$set(_vm.local_chart, "metrics", $$v)
                        },
                        expression: "local_chart.metrics",
                      },
                    }),
                    _vm._v(" "),
                    _vm.local_errors.metrics != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.local_errors.metrics) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Filters (Optional)"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-xs btn-secondary float-end",
                            attrs: {
                              type: "button",
                              id: "add-new-filter",
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#add-metric-filter-modal",
                            },
                            on: {
                              click: function ($event) {
                                _vm.add_new_option = "Filter"
                              },
                            },
                          },
                          [
                            _vm._m(0),
                            _vm._v(
                              " Add New\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("accordion-multi-select", {
                      attrs: {
                        id: "filters-multi-select",
                        options: _vm.filter_options,
                        field_name: "filters",
                        show_hidden_fields: true,
                      },
                      model: {
                        value: _vm.local_chart.filter,
                        callback: function ($$v) {
                          _vm.$set(_vm.local_chart, "filter", $$v)
                        },
                        expression: "local_chart.filter",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.local_chart.type != "textbox"
            ? _c("div", { class: { "col-4": _vm.render_large_chart } }, [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _vm.local_chart.type != "scorecard"
                      ? _c("label", { staticClass: "form-label" }, [
                          _vm._v("Order By"),
                        ])
                      : _c("label", { staticClass: "form-label" }, [
                          _vm._v("Order By (Top Value is Shown)"),
                        ]),
                    _vm._v(" "),
                    _vm.max_values.order_by > _vm.order_bys.length
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-xs",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addOrderBy()
                              },
                            },
                          },
                          [_vm._m(1)]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.order_bys, function (order, index) {
                      return _c(
                        "div",
                        { staticClass: "input-group mb-3" },
                        [
                          _c("accordion-multi-select", {
                            attrs: {
                              options: _vm.order_by_options,
                              show_hidden_fields: true,
                              field_name: "order_by_" + index,
                              max_num_values: 1,
                            },
                            model: {
                              value: order.value,
                              callback: function ($$v) {
                                _vm.$set(order, "value", $$v)
                              },
                              expression: "order.value",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-bs-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                            },
                            [_vm._v(_vm._s(order.direction))]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "dropdown-menu dropdown-menu-end" },
                            [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        order.direction = "ASC"
                                      },
                                    },
                                  },
                                  [_vm._v("Ascending")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        order.direction = "DESC"
                                      },
                                    },
                                  },
                                  [_vm._v("Descending")]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.local_chart.max_results,
                        expression: "local_chart.max_results",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number" },
                    domProps: { value: _vm.local_chart.max_results },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.local_chart,
                          "max_results",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm.local_chart.type != "scorecard" &&
                _vm.local_chart.type != "pie"
                  ? _c("div", { staticClass: "mb-3" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_chart.highlight_last_value,
                              expression: "local_chart.highlight_last_value",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "highlight-last-value",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.local_chart.highlight_last_value
                            )
                              ? _vm._i(
                                  _vm.local_chart.highlight_last_value,
                                  null
                                ) > -1
                              : _vm.local_chart.highlight_last_value,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.local_chart.highlight_last_value,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "highlight_last_value",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "highlight_last_value",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.local_chart,
                                  "highlight_last_value",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(3),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.local_chart.type != "scorecard" &&
                _vm.local_chart.type != "pie"
                  ? _c("div", { staticClass: "mb-3" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_chart.show_all_data_labels,
                              expression: "local_chart.show_all_data_labels",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "show-all-data-labels",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.local_chart.show_all_data_labels
                            )
                              ? _vm._i(
                                  _vm.local_chart.show_all_data_labels,
                                  null
                                ) > -1
                              : _vm.local_chart.show_all_data_labels,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.local_chart.show_all_data_labels,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "show_all_data_labels",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "show_all_data_labels",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.local_chart,
                                  "show_all_data_labels",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(4),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.local_chart.type == "column" ||
                _vm.local_chart.type == "horizontal_bar"
                  ? _c("div", { staticClass: "mb-3" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_chart.show_series_percentage,
                              expression: "local_chart.show_series_percentage",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "show-series-percentage",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.local_chart.show_series_percentage
                            )
                              ? _vm._i(
                                  _vm.local_chart.show_series_percentage,
                                  null
                                ) > -1
                              : _vm.local_chart.show_series_percentage,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.local_chart.show_series_percentage,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "show_series_percentage",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "show_series_percentage",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.local_chart,
                                  "show_series_percentage",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(5),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.local_chart.type != "scorecard" &&
                _vm.local_chart.type != "pie"
                  ? _c("div", { staticClass: "mb-3" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.local_chart.include_projections,
                              expression: "local_chart.include_projections",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "include-projections",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.local_chart.include_projections
                            )
                              ? _vm._i(
                                  _vm.local_chart.include_projections,
                                  null
                                ) > -1
                              : _vm.local_chart.include_projections,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.local_chart.include_projections,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "include_projections",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.local_chart,
                                      "include_projections",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.local_chart,
                                  "include_projections",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(6),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.local_chart.type != "textbox"
            ? _c("div", { staticClass: "mb-3" }, [
                _vm._m(7),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(
                    _vm.local_chart.temp_filters,
                    function (filter, index) {
                      return _c(
                        "div",
                        {
                          key: filter.pos,
                          staticClass:
                            "badge badge-primary me-2 p-1 px-2 d-inline-flex align-items-center",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(filter.text) +
                              "\n                            "
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-link p-0 ps-1",
                              attrs: { title: "Remove Filter" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeTempFilter(index)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-close" })]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("div", { staticClass: "mb-1" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Icon or Image URL (Optional)"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.textbox_details.icon_url,
                          expression: "textbox_details.icon_url",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.textbox_details.icon_url },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.textbox_details,
                            "icon_url",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-1" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Icon Background Color (Optional)"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.textbox_details.icon_color_class,
                            expression: "textbox_details.icon_color_class",
                          },
                        ],
                        staticClass: "form-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.textbox_details,
                              "icon_color_class",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "", disabled: "", selected: "" } },
                          [_vm._v("Select the icon color...")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "icon-red" } }, [
                          _vm._v("Red"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "icon-orange" } }, [
                          _vm._v("Orange"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "icon-yellow" } }, [
                          _vm._v("Yellow"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "icon-green" } }, [
                          _vm._v("Green"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "icon-blue" } }, [
                          _vm._v("Blue"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "icon-purple" } }, [
                          _vm._v("Purple"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Content"),
                      ]),
                      _vm._v(" "),
                      _c("vue-editor", {
                        attrs: { editorToolbar: _vm.customToolbar },
                        on: {
                          "text-change": function ($event) {
                            return _vm.textboxContentChange()
                          },
                        },
                        model: {
                          value: _vm.textbox_details.html_content,
                          callback: function ($$v) {
                            _vm.$set(_vm.textbox_details, "html_content", $$v)
                          },
                          expression: "textbox_details.html_content",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm._m(8),
              ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { id: "preview-button", disabled: false && _vm.form.busy },
              on: {
                click: function ($event) {
                  return _vm.previewChart()
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-btn fa-eye" }),
              _vm._v("Preview\n                "),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "add-metric-filter-modal",
          "data-bs-backdrop": "static",
          "aria-labelledby": "add-metric-filter-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end close-modal",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "add-metric-filter-modal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(
                    _vm._s(_vm._f("propercase")(_vm.add_new_option)) +
                      " Builder"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0 pb-0" },
              [
                _c("metric-filter-builder", {
                  ref: "mf_builder",
                  attrs: {
                    data_blend: _vm.local_chart.data_blend,
                    modal_id: "add-metric-filter-modal",
                    type: _vm.add_new_option,
                  },
                  on: { update: _vm.mfAdded },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", {
        staticClass: "fas fa-plus",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "Creating a new Filter option will add it to the Filters option dropdown.",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-plus" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Max Results ("),
      _c("em", [_vm._v("Optional")]),
      _vm._v(")"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "highlight-last-value" },
      },
      [
        _vm._v("Highlight Only the Last Value "),
        _c("i", {
          staticClass: "fas fa-info-circle",
          attrs: {
            "data-bs-toggle": "tooltip",
            title:
              "When checked, only the last column will be highlighted to visually emphasize the most recent value.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "show-all-data-labels" },
      },
      [
        _vm._v("Show All Data Labels "),
        _c("i", { staticClass: "fas fa-info-circle" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "show-series-percentage" },
      },
      [
        _vm._v("Show as Percentage of Series Total "),
        _c("i", { staticClass: "fas fa-info-circle" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "include-projections" },
      },
      [
        _vm._v("Include Projections "),
        _c("i", {
          staticClass: "fas fa-info-circle",
          attrs: {
            "data-bs-toggle": "tooltip",
            title:
              "When checked, Avid will project out future years' results based upon the trends of recent prior years.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Preview Filters "),
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: {
          "data-bs-toggle": "tooltip",
          title:
            "These are filters only applied to the chart while using the Chart Builder. They will be applied via the Report Page once 'in the wild.'",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("p", [_vm._v("Instruction of creating textbox:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "When includes an icon or image url, it will always appear on the left side (similar to scorecard)"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "3 types of heading allowed: Heading 1(24px), Heading 2(22px), Heading 3(20px), Normal(14px)"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("Image style: Max height 100%, max width 100%")]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://docs.google.com/spreadsheets/d/1AacDkFj4f4LgD5grQzW9OYh7aU2-QQj2hzdd3xU5rV4/edit?usp=sharing",
                target: "_blank",
              },
            },
            [_vm._v("See the icon library here")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }