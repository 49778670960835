var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row onboarding" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("nav", { staticClass: "navbar navbar-expand-lg pb-0 mt-lg-5" }, [
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "filter-navbar" },
          },
          [
            _c("ul", { staticClass: "flex-row d-flex navbar-nav w-100" }, [
              _c("li", { staticClass: "col-3 nav-item px-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link border-bottom border-4",
                    class: { "border-primary": _vm.step_num >= 1 },
                    attrs: {
                      href: "/register?step=1",
                      role: "button",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm.step_num == 1
                      ? _c("span", [_vm._v("Sign Up")])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "col-3 nav-item pe-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link border-bottom border-4",
                    class: {
                      "pe-none": _vm.step_num == 1,
                      "border-primary": _vm.step_num >= 2,
                    },
                    attrs: {
                      href: "/register?step=2",
                      role: "button",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm.step_num == 2
                      ? _c("span", [_vm._v("Organization")])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "col-3 nav-item pe-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link border-bottom border-4",
                    class: {
                      "pe-none": _vm.step_num == 2,
                      "border-primary": _vm.step_num >= 3,
                    },
                    attrs: {
                      href: "/register?step=3",
                      role: "button",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm.step_num == 3
                      ? _c("span", [_vm._v("Systems")])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "col-3 nav-item pe-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link border-bottom border-4",
                    class: {
                      "pe-none": _vm.step_num <= 3,
                      "border-primary": _vm.step_num >= 4,
                    },
                    attrs: {
                      href: "/register?step=4",
                      role: "button",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm.step_num == 4
                      ? _c("span", [_vm._v("Connect")])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card box-content",
          class: _vm.step_num == 4 ? "mb-3" : "mb-5",
        },
        [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _vm.step_num == 1
                ? _c("onboarding-personal-info", {
                    attrs: { error: _vm.error },
                    on: { nextStep: _vm.nextStep },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step_num == 2
                ? _c("onboarding-org-info", {
                    attrs: { user: _vm.local_user },
                    on: { nextStep: _vm.nextStep, reset: _vm.reset },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step_num == 3
                ? _c("onboarding-current-systems", {
                    attrs: {
                      user: _vm.local_user,
                      client: _vm.local_client,
                      integrations: _vm.integrations,
                    },
                    on: { nextStep: _vm.nextStep, reset: _vm.reset },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step_num == 4
                ? _c("onboarding-connect-crm", {
                    attrs: {
                      user: _vm.local_user,
                      client: _vm.local_client,
                      integrations: _vm.integrations,
                    },
                    on: { reset: _vm.reset },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-6" }, [
      _c("div", { staticClass: "d-lg-block d-none" }, [
        _c("h2", { staticClass: "text-primary mt-5 text-center" }, [
          _vm._v("Turn your data into donations"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row d-none d-lg-block" }, [
          _c("div", { staticClass: "col-sm-10 offset-sm-1 mt-3" }, [
            _c("img", {
              staticClass: "w-100",
              attrs: { src: "/img/screenshot.png" },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row d-none d-lg-block" }, [
          _c("div", { staticClass: "col-sm-10 offset-sm-1 mt-3" }, [
            _c("div", { staticClass: "card box-content" }, [
              _c(
                "div",
                { staticClass: "card-body d-flex align-items-center" },
                [
                  _c("img", {
                    staticClass: "avatar rounded-circle me-3",
                    attrs: {
                      src: "https://s3.us-west-2.amazonaws.com/nextafter-pulse/users%2F1726175140680_image+%2816%29.png",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      '"Avid seems to excel at integrating data from our current complicated donor data collection methods, turning it into actionable insights that lead to measurable improvements." '
                    ),
                    _c("small", [_vm._v("- Rebecca, FamilyLife Ministries")]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-block d-lg-none mb-3" }, [
        _c("h1", { staticClass: "text-primary mt-5 text-center" }, [
          _vm._v("Turn your data into donations"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "From custom benchmarks to unique insights, Avid AI is able to help you get more out of your existing systems. Get started today with your free 30-day trial."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-5 row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
          _c("img", {
            staticClass: "me-3",
            staticStyle: { width: "64px" },
            attrs: { src: "/img/lock.png", alt: "lock" },
          }),
          _vm._v(" "),
          _c("div", [
            _c("h5", { staticClass: "mb-2" }, [_vm._v("Your data is secure")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "Avid AI takes the security of your data seriously.\n                        "
              ),
              _c(
                "a",
                {
                  attrs: { href: "https://trust.avidai.com", target: "_blank" },
                },
                [_vm._v("Visit our trust center")]
              ),
              _vm._v(
                "\n                        to see our SOC2 compliance status and the steps taken to ensure your organization's privacy.\n                        "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }