var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "datasets", attrs: { id: "datablends" } },
    [
      _c("h4", [_vm._v("Data Blends")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          'A "data blend" is a unique view of your data that allows Avid AI to combine information from multiple sources. These data sources are what power the analysis done within Avid AI and can also serve as a source of data to push to your marketing and advertising tools.'
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "overflow-table" }, [
        _c(
          "table",
          {
            staticClass:
              "table table-responsive border rounded table-with-pagination",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.local_datablends, function (set, index) {
                return _c("tr", [
                  _c("td", { staticClass: "align-middle" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("propercase")(set.type)) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle" }, [
                    set.last_sync && set.last_sync.status == "failed"
                      ? _c("span", { staticClass: "badge badge-danger" }, [
                          _vm._v("Error"),
                        ])
                      : set.last_sync && set.last_sync.status == "processing"
                      ? _c("span", { staticClass: "badge badge-primary" }, [
                          _vm._v("Processing"),
                        ])
                      : set.last_sync == null &&
                        set.type.indexOf("benchmark") == -1
                      ? _c("span", { staticClass: "badge badge-secondary" }, [
                          _vm._v("Initializing"),
                        ])
                      : _c("span", { staticClass: "badge badge-success" }, [
                          _vm._v("Active"),
                        ]),
                  ]),
                  _vm._v(" "),
                  set.last_sync && set.last_sync.status == "failed"
                    ? _c("td", { staticClass: "align-middle" }, [
                        _vm._v(
                          "\n                        The last attempt to compile this Data Blend failed, likely due to a mapping issue. Return to your system under Connections>View Connections>Inclusion Filters or Column Mappings. The errored column(s) will be highlighted in red.\n                    "
                        ),
                      ])
                    : _c(
                        "td",
                        { staticClass: "align-middle" },
                        _vm._l(set.segments, function (seg) {
                          return _c(
                            "div",
                            {
                              staticClass:
                                "badge badge-secondary with-actions me-2 my-1 p-2",
                              class:
                                _vm.hover[set.id + "-" + seg.id] == true
                                  ? "hover"
                                  : "",
                              on: {
                                mouseover: function ($event) {
                                  return _vm.setHover(set.id, seg.id, true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.setHover(set.id, seg.id, false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(seg.name) +
                                  "\n                            "
                              ),
                              _c(
                                "transition",
                                { attrs: { name: "fade-slow" } },
                                [
                                  _vm.hover[set.id + "-" + seg.id] == true
                                    ? _c("div", { staticClass: "actions" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-link p-0 px-1",
                                            attrs: {
                                              title: "Edit Segment",
                                              href:
                                                "/segment/" +
                                                _vm.client.url +
                                                "/" +
                                                seg.id,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-edit",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-link p-0 px-1",
                                            attrs: { title: "Delete Segment" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteSegment(seg)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-trash",
                                            }),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "align-middle d-none d-md-table-cell" },
                    [_vm._v(_vm._s(_vm._f("date")(set.last_processed_at)))]
                  ),
                  _vm._v(" "),
                  set.type.indexOf("benchmark") == -1
                    ? _c(
                        "td",
                        {
                          staticClass: "align-middle text-end",
                          staticStyle: { "min-width": "118px" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-none",
                              attrs: {
                                href:
                                  "/segment/" +
                                  _vm.client.url +
                                  "/new?datablend=" +
                                  _vm.local_datablends[index].id,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/plus.svg",
                                  "data-bs-toggle": "tooltip",
                                  title: "Add Segment",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-none",
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                title: "Edit Data Blend",
                                href:
                                  "/integrations/" +
                                  _vm.client.url +
                                  "/data_blend/" +
                                  _vm.local_datablends[index].id,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/edit.svg",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-none me-0",
                              attrs: {
                                "data-bs-toggle": "tooltip",
                                title: "Force Rebuild Data Blend",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.refreshDataBlend(
                                    _vm.local_datablends[index].id
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/sync.svg",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary mb-3",
          on: {
            click: function ($event) {
              return _vm.clearCache()
            },
          },
        },
        [_vm._v("Clear Client Cache")]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.notification_message != ""
          ? _c("div", { staticClass: "notification success" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.notification_message) },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      _vm.notification_message = ""
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-times-circle" })]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-header-row" }, [
      _c("tr", [
        _c("th", { staticClass: "align-middle" }, [_vm._v("Data Blend Type")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle" }, [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle" }, [
          _vm._v("Segments / Messages"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle d-none d-md-table-cell" }, [
          _vm._v("Last Refreshed"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle" }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }