var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "insights-dashboard pt-2 mb-4" }, [
    _c("div", { staticClass: "row mx-2 mb-3" }, [
      _c("div", { staticClass: "col-6 p-0" }, [
        _c("h1", [_vm._v("Welcome, " + _vm._s(_vm.user.first_name) + "!")]),
        _vm._v(" "),
        _c("p", [_vm._v("Here are your top Insights for the day.")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 p-0" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary float-end mb-2",
            attrs: {
              href: "/insights/" + _vm.client.url + "/generate_ppt",
              id: "export-button",
            },
          },
          [
            _c("img", {
              staticClass: "icon pe-0",
              attrs: { src: "/img/icons/dialexa-icons/presention-chart.svg" },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ps-1" }, [
              _vm._v("Generate Presentation"),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4 insight-dashboard-info" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "card box-content p-3 d-flex flex-row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex-fill p-1" }, [
            _c("h5", [_vm._v("Time Period Data")]),
            _vm._v(" "),
            _c("ul", { staticClass: "mb-0" }, [
              _c("li", [
                _vm._v("Time Period: "),
                _c("em", [_vm._v(_vm._s(_vm.client.default_reporting_period))]),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Last Transaction Date: "),
                _c("em", [
                  _vm.meta_data.constituent != null
                    ? _c("span", [
                        _vm.meta_data.constituent._avid_max_display_date !=
                          null &&
                        _vm.meta_data.constituent._avid_max_display_date.date !=
                          null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.meta_data.constituent
                                      ._avid_max_display_date.date
                                  )
                                ) + " "
                              ),
                            ])
                          : _vm.meta_data.constituent._avid_max_display_date !=
                            null
                          ? _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.meta_data.constituent
                                        ._avid_max_display_date
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm.meta_data.constituent
                              ._max_overall_donation_date != null
                          ? _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.meta_data.constituent
                                        ._max_overall_donation_date.date
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _c("span", [_vm._m(1), _vm._v(" Loading")]),
                ]),
              ]),
              _vm._v(" "),
              _vm.meta_data.subscriber != null
                ? _c("li", [
                    _vm._v("Last Email Date: "),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(
                            _vm.meta_data.subscriber._max_overall_record_date
                          )
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-8" }, [
        _c("div", { staticClass: "card box-content p-3 d-flex flex-row" }, [
          _vm._m(2),
          _vm._v(" "),
          _vm.history != null && _vm.history.length > 0
            ? _c("div", { staticClass: "flex-fill p-1" }, [
                _c("h5", [_vm._v("Your Most Visited Reports")]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "mb-0" },
                  _vm._l(_vm.history, function (h) {
                    return _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/insights/" +
                              _vm.client.url +
                              "/" +
                              h.url +
                              "/" +
                              h.report_page_section_id,
                          },
                        },
                        [
                          _vm._v(_vm._s(h.name) + ": "),
                          _c("em", [_vm._v(_vm._s(h.title))]),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _c("div", { staticClass: "flex-fill p-1" }, [
                _c("h5", [_vm._v("Your Suggested Reports")]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "mb-0" },
                  _vm._l(_vm.report_pages.slice(0, 3), function (page) {
                    return _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/insights/" + _vm.client.url + "/" + page.url,
                          },
                        },
                        [_vm._v(_vm._s(page.name))]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mx-2 mb-5 top-5-metrics box-content" }, [
      _c("div", { staticClass: "col-2 p-0" }, [
        _c(
          "nav",
          {
            staticClass:
              "navbar bg-secondary nav-tab-side flex-column h-100 p-0",
          },
          [
            _c(
              "ul",
              { staticClass: "navbar-nav w-100 flex-column h-100" },
              _vm._l(_vm.insight_metrics, function (metric, index) {
                return _c(
                  "li",
                  {
                    staticClass:
                      "nav-item flex-fill d-flex align-items-center justify-content-center",
                    class: { "active-tab": metric.is_tab_active },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link w-100 h-100",
                        class:
                          metric.percentage_changed != null
                            ? ""
                            : "d-flex flex-column justify-content-center align-items-center",
                        attrs: {
                          href: "#",
                          role: "button",
                          "aria-expanded": "false",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setActiveTab(index)
                          },
                        },
                      },
                      [
                        metric.percentage_changed != null
                          ? _c("h2", [
                              _vm._v(_vm._s(_vm.formatValue(metric)) + " "),
                            ])
                          : _c("h5", { staticClass: "border-bottom-1" }, [
                              _vm._v(_vm._s(metric.metric_name) + " "),
                            ]),
                        _vm._v(" "),
                        metric.percentage_changed != null
                          ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
                              _c("div", { staticClass: "flex-grow-1" }, [
                                _vm._v(_vm._s(metric.metric_name)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge me-0 ms-1",
                                    class: {
                                      "bg-label-success":
                                        metric.percentage_changed > 0,
                                      "bg-label-danger":
                                        metric.percentage_changed < 0,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage_na")(
                                          metric.percentage_changed
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-10 p-0" },
        _vm._l(_vm.insight_metrics, function (metric, index) {
          return metric.is_tab_active
            ? _c("div", { key: metric.section_id, staticClass: "card" }, [
                metric.chart != null
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-8" }, [
                            _c("h4", [
                              _c("em", [
                                _vm._v(_vm._s(metric.section_title) + ":"),
                              ]),
                              _vm._v(" " + _vm._s(metric.chart.title) + " "),
                              _c("sup", [
                                metric.chart.chart.description != null
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-question-circle text-primary",
                                      attrs: {
                                        "data-bs-toggle": "tooltip",
                                        title: metric.chart.chart.description,
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(metric.chart.subtitle))]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-secondary float-end",
                                attrs: {
                                  href:
                                    "/insights/" +
                                    _vm.client.url +
                                    "/" +
                                    metric.page_url +
                                    "/" +
                                    metric.section_id,
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon me-2",
                                  attrs: {
                                    src: "/img/icons/dialexa-icons/chart.svg",
                                  },
                                }),
                                _vm._v("View Insights Details"),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col mb-3 max-height-800" },
                            [
                              _c("chart-template", {
                                key: "chart" + metric.section_id,
                                attrs: { chart: metric.chart.chart },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "fade-slow" } }, [
                          metric.summary != null
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex pink-gradient-left summary pt-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "primary-chart-insight-icon mt-3 mx-3",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/flag.svg",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("h5", [_vm._v("What does this mean?")]),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(metric.summary),
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex pink-gradient-left summary pt-3",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "primary-chart-insight-icon m-3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "spinner-border spinner-border-sm",
                                          attrs: { role: "status" },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "visually-hidden",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("h5", [
                                      _vm._v("Your data is being analyzed."),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "The results will be displayed here once the analysis is complete."
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                        ]),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "card-body" }, [_vm._m(3, true)]),
              ])
            : _vm._e()
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mx-2" }, [
      _vm.report_pages.length > 0
        ? _c(
            "div",
            { staticClass: "p-0", attrs: { id: "available-reports" } },
            [
              _c("h2", { staticClass: "mb-3" }, [_vm._v("Available Reports")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.page_by_group, function (group, index) {
                  return _c(
                    "div",
                    { staticClass: "mb-3", class: group.class_name },
                    [
                      _c(
                        "div",
                        { staticClass: "card box-content report-preview-card" },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            group.pages.length > 1
                              ? _c("div", [
                                  group.icon != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "insights-dashboard-group-icon mt-2 me-2 float-start",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "circle-icon",
                                            attrs: {
                                              src:
                                                "/img/icons/dialexa-icons/" +
                                                group.icon,
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c("h3", { staticClass: "pt-2" }, [
                                        _vm._v(_vm._s(group.group_name)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        {
                                          class:
                                            group.pages.length < 5
                                              ? "single-column"
                                              : "double-column",
                                        },
                                        _vm._l(
                                          group.pages,
                                          function (page, index) {
                                            return _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "/insights/" +
                                                      _vm.client.url +
                                                      "/" +
                                                      page.url,
                                                  },
                                                },
                                                [_vm._v(_vm._s(page.name))]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _c("div", [
                                  group.icon != null
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "insights-dashboard-group-icon mt-2 me-2 float-start",
                                        },
                                        [
                                          group.class_name != "col-12"
                                            ? _c("img", {
                                                staticClass: "circle-icon",
                                                attrs: {
                                                  src:
                                                    "/img/icons/dialexa-icons/" +
                                                    group.icon,
                                                },
                                              })
                                            : group.pages[0].sections[0]
                                                .primary_chart != null
                                            ? _c(
                                                "div",
                                                { staticClass: "square-icon" },
                                                [
                                                  _c("chart-template", {
                                                    staticClass:
                                                      "full-width-chart-preview",
                                                    attrs: {
                                                      chart:
                                                        group.pages[0]
                                                          .sections[0]
                                                          .primary_chart.chart,
                                                      id:
                                                        "chart" +
                                                        group.pages[0]
                                                          .sections[0]
                                                          .primary_chart.id,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col report-preview-card--text",
                                      },
                                      [
                                        _c("h3", { staticClass: "pt-2" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-decoration-none",
                                              attrs: {
                                                href:
                                                  "/insights/" +
                                                  _vm.client.url +
                                                  "/" +
                                                  group.pages[0].url,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(group.pages[0].name)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-3" }, [
                                          _vm._v(
                                            _vm._s(group.pages[0].description)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "wrapper d-flex mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-preview-card--text-preview",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "border-start-0",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "h5" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            group.pages[0]
                                                              .sections.length
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "p" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            group.pages[0]
                                                              .sections.length >
                                                              1
                                                              ? "Sections"
                                                              : "Section"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "h5" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          group.pages[0]
                                                            .charts_count
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "p" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          group.pages[0]
                                                            .charts_count > 1
                                                            ? "Charts"
                                                            : "Chart"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "h5" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          group.pages[0]
                                                            .insights_count
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "p" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          group.pages[0]
                                                            .insights_count > 1
                                                            ? "Insights"
                                                            : "Insight"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "float-end" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "me-3",
                                                    attrs: {
                                                      href:
                                                        "/insights/" +
                                                        _vm.client.url +
                                                        "/" +
                                                        group.pages[0].url,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary",
                                                      },
                                                      [_vm._v("Explore")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _c("div", [_vm._m(4)]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex-shrink-1 insights-dashboard-group-icon mt-2 me-2" },
      [
        _c("img", {
          staticClass: "circle-icon bg-tertiary",
          attrs: { src: "/img/icons/dialexa-icons/clock-tertiary.svg" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-inline-block spinner-border spinner-border-sm ms-2",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex-shrink-1 insights-dashboard-group-icon mt-2 me-2" },
      [
        _c("img", {
          staticClass: "circle-icon bg-secondary",
          attrs: {
            src: "/img/icons/dialexa-icons/chart-infographic-secondary.svg",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "asset-overlay" }, [
      _c("div", {
        staticClass: "overlay",
        staticStyle: {
          "border-bottom-left-radius": "0",
          "border-top-left-radius": "0",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "cta" }, [
        _c("div", { staticClass: "row d-flex align-items-center mx-3" }, [
          _c("div", { staticClass: "col-2" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-white",
                attrs: { role: "status" },
              },
              [
                _c("span", { staticClass: "visually-hidden" }, [
                  _vm._v("Loading..."),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("h5", { staticClass: "text-white mt-2" }, [
              _vm._v("Loading..."),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card box-content mb-3" }, [
      _c("div", { staticClass: "card-body mt-3 text-center" }, [
        _c("h3", { staticClass: "text-center" }, [
          _vm._v("No reports have been created for this client yet."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }