var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive border rounded" }, [
    _vm.chart_selected.data
      ? _c("div", [
          _c("table", { staticClass: "table m-0" }, [
            _c("thead", [
              _c(
                "tr",
                { staticClass: "table-header-row" },
                [
                  _c(
                    "th",
                    {
                      staticStyle: { width: "15%" },
                      on: {
                        click: function ($event) {
                          return _vm.sortByKey("label")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-row" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.chart_selected.group_by[0].text) +
                            "\n                            "
                        ),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("img", {
                            staticClass: "sort-key",
                            class:
                              _vm.sort_key == "label" && _vm.sort_order == "asc"
                                ? "sort-key-active"
                                : "",
                            attrs: {
                              src: "/img/icons/dialexa-icons/chevron-up.svg",
                            },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "sort-key",
                            class:
                              _vm.sort_key == "label" && _vm.sort_order == "des"
                                ? "sort-key-active"
                                : "",
                            attrs: {
                              src: "/img/icons/dialexa-icons/chevron-down.svg",
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(Object.keys(_vm.chart_selected.data), function (key) {
                    return _c(
                      "th",
                      {
                        staticClass: "align-middle",
                        on: {
                          click: function ($event) {
                            return _vm.sortByKey(key)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(key) +
                              "\n                            "
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key == key && _vm.sort_order == "asc"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-up.svg",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "sort-key",
                              class:
                                _vm.sort_key == key && _vm.sort_order == "des"
                                  ? "sort-key-active"
                                  : "",
                              attrs: {
                                src: "/img/icons/dialexa-icons/chevron-down.svg",
                              },
                            }),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.table_data_shown, function (data) {
                return _c(
                  "tr",
                  {
                    staticClass: "table-template-row",
                    class:
                      _vm.applied_chart_filter.if_chart_filter_applied &&
                      _vm.id == _vm.applied_chart_filter.section_chart_id &&
                      data.label !=
                        _vm.applied_chart_filter.chart_filters[0].value
                        ? "grayscale-table-template-row"
                        : "",
                    on: {
                      click: function ($event) {
                        _vm.if_on_view && !_vm.if_benchmark_report
                          ? _vm.exploreData(data)
                          : null
                      },
                    },
                  },
                  _vm._l(data, function (value, key, index) {
                    return _c("td", { staticClass: "ps-3 align-middle" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            value == null
                              ? "(not set)"
                              : _vm.applyFormatToCurrentValue(value, key)
                          ) +
                          "\n                    "
                      ),
                    ])
                  }),
                  0
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("tfoot", { staticClass: "table-border-bottom-0" }, [
              _c("tr", [
                _c("td", { staticClass: "py-0", attrs: { colspan: "100%" } }, [
                  _c("div", { staticClass: "table-pagination" }, [
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _c("span", { staticClass: "d-none d-lg-inline" }, [
                        _vm._v("Showing: "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.num_per_page,
                              expression: "num_per_page",
                            },
                          ],
                          staticClass: "form-select",
                          attrs: { name: "num_per_page", id: "num_per_page" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.num_per_page = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.reloadPagination()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "5000" } }, [
                            _vm._v("ALL"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.num_per_page_options, function (num) {
                            return _c("option", { domProps: { value: num } }, [
                              _vm._v(_vm._s(num)),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Results ")]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-pagination--group text-center" },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.item_interval) +
                            " of " +
                            _vm._s(_vm.table_data.length) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-pagination--group" }, [
                      _vm.page_count > 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: { disabled: _vm.page_index == 0 },
                              on: {
                                click: function ($event) {
                                  return _vm.moveToPrevPage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-left.svg",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.page_index_array.length <= 3
                        ? _c(
                            "div",
                            _vm._l(_vm.page_index_array, function (page) {
                              return _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  class:
                                    page == _vm.page_index + 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(page)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(page) +
                                      "\n                                    "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            [
                              _vm._l(
                                _vm.page_index_array.slice(0, 2),
                                function (page) {
                                  return _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      class:
                                        page == _vm.page_index + 1
                                          ? "current-page"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveToPage(page)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(page) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.page_index > 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    ...\n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index >= 2 &&
                              _vm.page_index < _vm.page_index_array.length - 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-none px-1 current-page",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.page_index + 1) +
                                          " \n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page_index < _vm.page_index_array.length - 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-1",
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    ...\n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-1",
                                  class:
                                    _vm.page_index ==
                                    _vm.page_index_array.length - 1
                                      ? "current-page"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToPage(
                                        _vm.page_index_array.length
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.page_index_array.length) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                      _vm._v(" "),
                      _vm.page_count > 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-none px-1",
                              attrs: {
                                disabled: _vm.page_index == _vm.page_count - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.moveToNextPage()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "/img/icons/dialexa-icons/chevron-right.svg",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "d-inline-block text-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "text-secondary_v1 mb-1" }, [
      _c("i", { staticClass: "fa-solid fa-chart-simple" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-secondary_v1 mb-2" }, [
      _c("em", [
        _vm._v("Press the "),
        _c("strong", [_vm._v("Preview")]),
        _vm._v(" button to render the chart"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }