var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "report-builder-filter-settings" }, [
      _c("h5", { staticClass: "mt-4" }, [_vm._v("Report Filter Settings")]),
      _vm._v(" "),
      _c("div", { staticClass: "card box-content mt-2" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("nav", { staticClass: "bg-secondary mt-0 rounded" }, [
                _c("div", { staticClass: "d-flex pt-3 px-2" }, [
                  _c(
                    "span",
                    { staticClass: "py-1 ms-1 me-3 mb-0 h5 text-nowrap" },
                    [_vm._v("Static Page Filters:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("v-select", {
                        staticClass:
                          "searchable-select input_v1 column-display w-50",
                        attrs: {
                          id: "page-filter-dropdown",
                          options: _vm.page_filter_options,
                          placeholder: "Select the Filter to add to Report...",
                          searchable: true,
                          selectable: (option) => option.text != "",
                          label: "text",
                          filterable: false,
                        },
                        on: {
                          search: _vm.searchPageFilterOption,
                          input: _vm.emitPageFilterChange,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selected-option",
                            fn: function ({ text, category }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "Select the Filter to add to the report..."
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "option",
                            fn: function ({ text, category }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "suboption",
                                    class:
                                      category == null || category == ""
                                        ? "show"
                                        : "",
                                    attrs: { "data-subcategory": category },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(text) +
                                        "\n                                            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "no-options",
                            fn: function ({ search, searching, loading }) {
                              return [
                                _c("div", { staticClass: "suboption show" }, [
                                  _c("em", [_vm._v("No results found")]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: {
                            "data-bs-toggle": "modal",
                            "data-bs-target": "#page-filter-modal",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addPageFilter()
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-btn fa-plus" })]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex p-2" },
                  _vm._l(_vm.page_level_filters_list, function (filter, index) {
                    return _c(
                      "span",
                      { staticClass: "badge badge-dark me-2" },
                      [
                        _vm._v(
                          "\n                                     " +
                            _vm._s(filter.name) +
                            " "
                        ),
                        _c("i", {
                          staticClass: "fa fa-remove",
                          attrs: { id: "remove-filter" },
                          on: {
                            click: function ($event) {
                              return _vm.removePageFilterByIndex(index)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "nav",
                {
                  staticClass: "navbar navbar-expand bg-secondary mt-2 rounded",
                },
                [
                  _c("div", { staticClass: "container-fluid" }, [
                    _c("span", { staticClass: "navbar-brand mb-0 ms-1 h5" }, [
                      _vm._v("User-Defined Filters:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse navbar-collapse",
                        attrs: { id: "filter-navbar" },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "navbar-nav me-auto mb-2 mb-lg-0" },
                          [
                            _vm._l(
                              _vm.dimensional_filters_list,
                              function (group, group_index) {
                                return _c(
                                  "li",
                                  {
                                    staticClass:
                                      "nav-item dropdown me-3 d-flex",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link dropdown-toggle",
                                        attrs: {
                                          href: "#",
                                          role: "button",
                                          "data-bs-toggle": "dropdown",
                                          "data-bs-auto-close": "outside",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v(_vm._s(group.group_name))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "dropdown-menu dropdown-menu-overflow px-2",
                                      },
                                      [
                                        _vm._l(
                                          group.filters,
                                          function (filter, filter_index) {
                                            return _c(
                                              "div",
                                              { staticClass: "mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-nowrap",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  filter.label
                                                                ) + " "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-remove mt-1 ms-1",
                                                            attrs: {
                                                              id: "remove-filter",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.editFilterWithinGroup(
                                                                  filter_index,
                                                                  group_index,
                                                                  "Delete"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn form-select btn-field min-width-125",
                                                          attrs: {
                                                            type: "button",
                                                            "data-bs-toggle":
                                                              "modal",
                                                            "data-bs-target":
                                                              "#dimension-filter-modal",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editFilterWithinGroup(
                                                                filter_index,
                                                                group_index,
                                                                "Update"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._m(0, true)]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reorder-btn-containter",
                                                      },
                                                      [
                                                        filter_index != 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "reorder-btn-filter-dropdown mt-1 mb-2",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.moveFilterUpInGroup(
                                                                        group_index,
                                                                        filter_index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-arrow-up",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        filter_index !=
                                                        group.filters.length - 1
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "reorder-btn-filter-dropdown",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.moveFilterDownInGroup(
                                                                        group_index,
                                                                        filter_index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-arrow-down",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("hr", {
                                                  staticClass:
                                                    "mt-2 section-divider",
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-secondary",
                                            attrs: {
                                              "data-bs-toggle": "modal",
                                              "data-bs-target":
                                                "#dimension-filter-modal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editFilterWithinGroup(
                                                  null,
                                                  group_index,
                                                  "Add"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-btn fa-plus",
                                            }),
                                            _vm._v(
                                              " Add Filter\n                                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "mt-2" }, [
                                      _c("a", { attrs: { href: "#" } }, [
                                        _c("i", {
                                          staticClass: "fa fa-edit",
                                          attrs: {
                                            "data-bs-toggle": "modal",
                                            "data-bs-target":
                                              "#filter-group-modal",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editFilterGroup(
                                                group_index,
                                                "Update"
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    group.filters.length == 0
                                      ? _c("span", { staticClass: "mt-2" }, [
                                          _c("a", { attrs: { href: "#" } }, [
                                            _c("i", {
                                              staticClass: "fa fa-remove",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editFilterGroup(
                                                    group_index,
                                                    "Delete"
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item float-end" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    "data-bs-toggle": "modal",
                                    "data-bs-target": "#filter-group-modal",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editFilterGroup(null, "Add")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-btn fa-plus" }),
                                  _vm._v(
                                    " Add Filter Group\n                                            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "dimension-filter-modal",
          "aria-labelledby": "dimension-filter-modal",
          "data-bs-backdrop": "static",
          "aria-hidden": "true",
        },
      },
      [
        _c("report-builder-udf-popup", {
          attrs: {
            action: _vm.action_for_filter_in_group,
            client_id: _vm.report_page.client_id,
            dimensional_filter: _vm.dimensional_filter,
            data_blend: _vm.data_blend,
            metrics_filters: _vm.local_metrics_filters,
          },
          on: { updateFilterWithinGroup: _vm.updateFilterWithinGroup },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "filter-group-modal",
          "aria-labelledby": "filter-group-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "filter-group-modal",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal("filter-group-modal")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [
                  _vm._v(
                    _vm._s(this.action_for_filter_group) + " a Filter Group"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body pt-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Filter Group Name"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dimensional_filter_group.group_name,
                          expression: "dimensional_filter_group.group_name",
                        },
                      ],
                      staticClass: "form-control",
                      class:
                        _vm.local_filter_group_errors.group_name != ""
                          ? "is-invalid"
                          : "",
                      attrs: { type: "text", id: "filter-group-name-input" },
                      domProps: {
                        value: _vm.dimensional_filter_group.group_name,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.clearErrorForGroupField("group_name")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.dimensional_filter_group,
                            "group_name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.local_filter_group_errors.group_name != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.local_filter_group_errors.group_name
                                ) +
                                "\n                                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: {
                          type: "submit",
                          id: "save-button",
                          disabled: _vm.form.busy,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.updateFilterGroup()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-btn",
                          class:
                            _vm.action_for_filter_group == "Add"
                              ? "fa-plus"
                              : "fa-save",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(this.action_for_filter_group) +
                            " Filter Group\n                                "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "page-filter-modal",
          "aria-labelledby": "page-filter-modal",
          "data-bs-backdrop": "static",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
              _c("div", { staticClass: "text-center w-100" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none float-end close-modal",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "page-filter-modal",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal("page-filter-modal")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-2" }, [_vm._v("Filter Builder")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body pt-0 pb-0" },
              [
                _c("metric-filter-builder", {
                  ref: "mf_builder",
                  attrs: {
                    data_blend: _vm.data_blend,
                    modal_id: "page-filter-modal",
                    client_id: _vm.report_page.client_id,
                    type: "filter",
                  },
                  on: { update: _vm.pageFilterAdded },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "selected-value" }, [
      _c("i", { staticClass: "fa fa-btn fa-edit" }),
      _c("span", [_vm._v("Edit Filter")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }