var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "reportSection",
      staticClass: "report-section",
      attrs: { id: "section-start" },
    },
    [
      _c(
        "div",
        {
          staticClass: "applied-chart-filter mb-4",
          class: _vm.applied_chart_filter.if_chart_filter_applied
            ? ""
            : "hidden",
        },
        [
          _c(
            "div",
            { staticClass: "applied-chart-filter-direct-descendant-div" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm.applied_chart_filter.if_chart_filter_applied
                ? _c("div", { staticClass: "applied-chart-filter__content" }, [
                    _c("label", [
                      _vm._v(
                        "Chart Filter (Click on the X button to remove the chart filter selected)"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("h4", [
                      _vm._v(
                        _vm._s(_vm.applied_chart_filter.chart_selected.title)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Segment selected: " + _vm._s(_vm.getSegmentName())
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "applied-chart-filter-direct-descendant-div" },
            [
              _c("div", { staticClass: "applied-chart-filter__actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: {
                      type: "button",
                      "data-bs-toggle": "modal",
                      "data-bs-target":
                        "#save-segment-modal-" + _vm.page_section.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSegmentDetails()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon svg-filter-primary",
                      attrs: {
                        src: "/img/icons/dialexa-icons/save-floppy-disk.svg",
                      },
                    }),
                    _vm._v("Save Segment\n                "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      "data-bs-toggle": "modal",
                      "data-bs-target":
                        "#push-segment-modal-" + _vm.page_section.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSegmentDetails()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: "/img/icons/dialexa-icons/direction-sign.svg",
                      },
                    }),
                    _vm._v("Push Segment\n                "),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.removedAppliedChartFilter()
                },
              },
            },
            [_c("i", { staticClass: "fa fa-close" })]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.any_active_charts == false
        ? _c(
            "div",
            { staticClass: "border rounded mb-3 pt-3 px-3 position-relative" },
            [
              _c("transition", { attrs: { name: "fade", duration: "300" } }, [
                _vm.if_refreshing
                  ? _c("div", { staticClass: "loading-overlay rounded" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block spinner-border spinner-border-lg",
                          attrs: { role: "status" },
                        },
                        [
                          _c("span", { staticClass: "visually-hidden" }, [
                            _vm._v("Loading"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", [_vm._v("Loading...")]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ],
            1
          )
        : _c("div", { staticClass: "chart-grid" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(_vm.local_charts, function (chart, i) {
                  return _vm.checkIfShowChart(chart.chart)
                    ? _c(
                        "div",
                        {
                          key: chart.id,
                          staticClass: "size-container",
                          class: chart.size_class,
                          attrs: { id: chart.id, "data-size": chart.size },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "chart-container border rounded mb-3 pt-3 px-3 position-relative",
                                  class: _vm.if_benchmark_report
                                    ? "benchmark-" + chart.subtitle
                                    : "",
                                },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: { name: "fade", duration: "300" },
                                    },
                                    [
                                      _vm.if_refreshing &&
                                      chart.id !=
                                        _vm.applied_chart_filter
                                          .section_chart_id &&
                                      chart.chart.type != "textbox"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "loading-overlay rounded",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-inline-block spinner-border spinner-border-lg",
                                                  attrs: { role: "status" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "visually-hidden",
                                                    },
                                                    [_vm._v("Loading")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("p", [_vm._v("Loading...")]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  chart.chart.type != "scorecard" &&
                                  chart.chart.type != "textbox" &&
                                  chart.chart.type != "table"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-none zoom-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.zoomOnChart(chart, i)
                                            },
                                          },
                                        },
                                        [
                                          !chart.if_zoomed_in
                                            ? _c("img", {
                                                staticClass: "icon pe-0",
                                                attrs: {
                                                  src: "/img/icons/dialexa-icons/maximize-4.svg",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          chart.if_zoomed_in
                                            ? _c("img", {
                                                staticClass: "icon pe-0",
                                                attrs: {
                                                  src: "/img/icons/dialexa-icons/minimize-4.svg",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  chart.chart.type != "scorecard" &&
                                  chart.chart.type != "textbox"
                                    ? _c("div", [
                                        chart.chart.title != null &&
                                        chart.chart.title != ""
                                          ? _c(
                                              "h4",
                                              {
                                                staticClass: "chart-title",
                                                class:
                                                  chart.chart.subtitle !=
                                                    null &&
                                                  chart.chart.subtitle != ""
                                                    ? "mb-1"
                                                    : "mb-3",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(chart.chart.title) +
                                                    " "
                                                ),
                                                _c("sup", [
                                                  chart.chart.description !=
                                                  null
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fas fa-question-circle text-primary",
                                                        attrs: {
                                                          "data-bs-toggle":
                                                            "tooltip",
                                                          title:
                                                            chart.chart
                                                              .description,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        chart.chart.subtitle != null &&
                                        chart.chart.subtitle != ""
                                          ? _c(
                                              "p",
                                              { staticClass: "chart-subtitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(chart.chart.subtitle)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : chart.chart.type == "scorecard"
                                    ? _c("div", [
                                        _c("p", { staticClass: "mb-2" }, [
                                          _vm._v(
                                            _vm._s(chart.chart.title) + " "
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-3 grow",
                                      class: chart.if_zoomed_in
                                        ? "zoomed-in-chart"
                                        : "",
                                    },
                                    [
                                      _c("chart-template", {
                                        attrs: {
                                          chart: chart.chart,
                                          id: chart.id,
                                          if_refreshing: _vm.if_refreshing,
                                          if_on_view: true,
                                          applied_chart_filter:
                                            _vm.applied_chart_filter,
                                          if_benchmark_report:
                                            _vm.if_benchmark_report,
                                        },
                                        on: { exploreChart: _vm.exploreChart },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.if_benchmark_report
                                ? _c(
                                    "transition",
                                    { attrs: { name: "slide" } },
                                    [
                                      chart.is_primary &&
                                      _vm.local_section.summary != null &&
                                      _vm.local_section.summary != ""
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card box-content mb-3 primary-chart-insight",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card-body d-flex pink-gradient-left",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "primary-chart-insight-icon mt-2 me-2",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "icon",
                                                        attrs: {
                                                          src: "/img/icons/dialexa-icons/flag.svg",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "What does this mean?"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.local_section
                                                            .summary
                                                        ),
                                                      },
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.if_benchmark_report && _vm.section_index == 1
                  ? _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "float-end" }, [
                        _c("h5", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.goToNextSection()
                                },
                              },
                            },
                            [
                              _vm._v("Next Page: Benchmarks by Giving Level "),
                              _c("i", {
                                staticClass: "fa fa-solid fa-arrow-right",
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
      _vm._v(" "),
      (_vm.local_section.takeaways == null ||
        _vm.local_section.takeaways.length > 0) &&
      _vm.any_active_charts &&
      !_vm.if_benchmark_report
        ? _c(
            "div",
            { staticClass: "card box-content key-takeaways-section col-12" },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "mb-3" }, [_vm._v("Key Takeaways")]),
                _vm._v(" "),
                _vm.local_section.takeaways == null
                  ? _c("div", { staticClass: "key-takeaways-none" }, [
                      _c("p", { staticClass: "mb-0" }),
                      _vm._m(2),
                      _vm._v(
                        "Your data is being analyzed. The results will be displayed here once the analysis is complete."
                      ),
                      _c("p"),
                    ])
                  : _c(
                      "ul",
                      { staticClass: "key-takeaways-list" },
                      _vm._l(_vm.local_section.takeaways, function (takeaway) {
                        return _c("li", { staticClass: "key-takeaways-item" }, [
                          _c("div", { staticClass: "card box-content" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "key-takeaways-item--icon mt-2 me-3",
                                },
                                [
                                  takeaway.icon == "money"
                                    ? _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/dollar-square.svg",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  takeaway.icon == "number"
                                    ? _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/people.svg",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  takeaway.icon == "percent"
                                    ? _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/percentage-square.svg",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  takeaway.icon == "other"
                                    ? _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/note-2.svg",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "key-takeaways-item--text" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "key-takeaways-item--text--heading",
                                    },
                                    [
                                      _c("h5", {
                                        domProps: {
                                          innerHTML: _vm._s(takeaway.title),
                                        },
                                      }),
                                      _vm._v(" "),
                                      takeaway.url != null
                                        ? _c(
                                            "span",
                                            { staticClass: "explore" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-question-circle text-primary",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: takeaway.url },
                                                },
                                                [
                                                  _vm._v("Explore "),
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-solid fa-arrow-right",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "key-takeaways-item--text--body",
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(takeaway.html),
                                        },
                                      }),
                                      _c("br"),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade push-segment-modal new-modal",
          attrs: {
            id: "push-segment-modal-" + _vm.page_section.id,
            "data-bs-backdrop": "static",
            "aria-labelledby": "push-segment-modal",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content mx-2" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none close-modal",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss":
                        "push-segment-modal-" + _vm.page_section.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal(
                          "push-segment-modal-" + _vm.page_section.id
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body pt-0" }, [
                _c("h3", [_vm._v("Push Segment")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Fill out the details for your selected segment. Once the segment is created you can continue to push it to a pathway."
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.segment_selected
                    ? _c("div", { staticClass: "segment-summary-item" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.segment_selected.name) + " "),
                            _vm.segment_selected.sizes
                              ? _c("small", [
                                  _vm._v(
                                    "| " +
                                      _vm._s(
                                        _vm._f("number_with_zero")(
                                          _vm.getDefaultSize(
                                            _vm.segment_selected.sizes
                                          )
                                        )
                                      ) +
                                      " Contacts"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Source: " + _vm._s(_vm.page.data_blend.name)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.if_proceed_select_pathway
                          ? _c("div", { staticClass: "col-4" }, [
                              !_vm.segment_selected.id
                                ? _c("div", [
                                    _vm._m(4),
                                    _vm._v(
                                      "Segment Creating...\n                                "
                                    ),
                                  ])
                                : _c("div", [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v("Segment Created!"),
                                  ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                !_vm.if_proceed_select_pathway
                  ? _c("div", { staticClass: "d-flex flex-column my-3" }, [
                      _c("div", [
                        _c("label", { attrs: { required: "" } }, [
                          _vm._v("Segment Name"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.segment_selected.name,
                              expression: "segment_selected.name",
                            },
                          ],
                          staticClass: "form-control",
                          class:
                            _vm.errors.segment_name_push != ""
                              ? "is-invalid"
                              : "",
                          attrs: { type: "text", name: "segment_name" },
                          domProps: { value: _vm.segment_selected.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.segment_selected,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.segment_name_push != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.errors.segment_name_push) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _c("div", { staticClass: "d-flex flex-column my-3" }, [
                      _c("div", { staticClass: "w-100" }, [
                        _vm._m(5),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "scroll-view",
                            class: _vm.connections.length >= 3 ? "pe-2" : "",
                            staticStyle: { "max-height": "200px" },
                          },
                          _vm._l(_vm.connections, function (conn, conn_index) {
                            return _c(
                              "div",
                              {
                                staticClass: "card box-content long-card",
                                class:
                                  conn.id === _vm.pathway_selected.id
                                    ? "long-card-selected"
                                    : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-body long-card__body",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changePathwaySelection(conn)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "long-card__radio d-flex",
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "form-radio-input",
                                          attrs: { type: "radio", id: conn.id },
                                          domProps: {
                                            value: conn.id,
                                            checked:
                                              conn.id ==
                                              _vm.pathway_selected.id,
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "long-card__content d-flex flex-row justfy-content-start gap-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column gap-1",
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "avatar rounded-circle avatar-sm bg-white",
                                              attrs: {
                                                src: conn.integration.logo_url,
                                                alt: conn.integration.name,
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column gap-1",
                                          },
                                          [
                                            _c("h5", [
                                              _vm._v(
                                                _vm._s(
                                                  conn.integration_id ==
                                                    "71b0c3b0-8390-49ec-9984-1c04b7384d38"
                                                    ? "File Export"
                                                    : conn.name
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeModal(
                              "push-segment-modal-" + _vm.page_section.id
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                           Cancel\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.if_proceed_select_pathway
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary w-50",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.proceedSelectPathway()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Confirm and Select Pathway\n                        "
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-primary col-6",
                            attrs: {
                              disabled:
                                !_vm.pathway_selected.id ||
                                !_vm.segment_selected.id,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.directToPushSegmentPage()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Review and Confirm\n                        "
                            ),
                          ]
                        ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade save-segment-modal new-modal",
          attrs: {
            id: "save-segment-modal-" + _vm.page_section.id,
            "data-bs-backdrop": "static",
            "aria-labelledby": "save-segment-modal",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content mx-2" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-none close-modal",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss":
                        "save-segment-modal-" + _vm.page_section.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal(
                          "save-segment-modal-" + _vm.page_section.id
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body pt-0" }, [
                _c("h3", [_vm._v("Save Segment")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Save the segment that you want to save. Once the segment is created you can view it in Pathways - Manage Tab."
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.applied_chart_filter &&
                  _vm.applied_chart_filter.chart_selected &&
                  _vm.applied_chart_filter.chart_filters.length
                    ? _c("div", { staticClass: "segment-summary-item" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.segment_selected.name) + " "),
                            _vm.segment_selected.size
                              ? _c("small", [
                                  _vm._v(
                                    "| " +
                                      _vm._s(
                                        _vm._f("number_with_zero")(
                                          _vm.segment_selected.size
                                        )
                                      ) +
                                      " Contacts"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Source: " + _vm._s(_vm.page.data_blend.name)
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-column my-3" }, [
                  _c("div", [
                    _c("label", { attrs: { required: "" } }, [
                      _vm._v("Segment Name"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.segment_selected.name,
                          expression: "segment_selected.name",
                        },
                      ],
                      staticClass: "form-control",
                      class:
                        _vm.errors.segment_name_save != "" ? "is-invalid" : "",
                      attrs: { type: "text", name: "segment_name" },
                      domProps: { value: _vm.segment_selected.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.segment_selected,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.segment_name_save != ""
                      ? _c(
                          "div",
                          { staticClass: "text-danger invalid-feedback" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errors.segment_name_save) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row justify-content-center gap-3 mt-3",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeModal(
                              "save-segment-modal-" + _vm.page_section.id
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                           Cancel\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-50",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.saveSegment()
                          },
                        },
                      },
                      [
                        _vm.if_saving_segment
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-inline-block spinner-border spinner-border-sm",
                                attrs: { role: "status" },
                              },
                              [_c("span", { staticClass: "visually-hidden" })]
                            )
                          : _vm._e(),
                        _vm._v(" Save Segment\n                        "),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "applied-chart-filter__icon" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/img/icons/dialexa-icons/filter-sharp.svg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "textbox-template" }, [
      _c("div", { staticClass: "d-flex flex-row" }, [
        _c(
          "div",
          { staticClass: "flex-shrink-1 insights-dashboard-group-icon me-2" },
          [
            _c("div", { staticClass: "icon-red me-2" }, [
              _c("img", {
                attrs: { src: "/img/icons/dialexa-icons/warning-circle.svg" },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-fill" }, [
          _c("h1", [_vm._v("Invalid Filter Selection")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Based upon the filters selected, there are no records that match your criteria. Please update your filters and try again."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-inline-block spinner-border spinner-border-sm",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 d-flex flex-column gap-1" }, [
      _c("img", {
        staticClass: "modal-icon",
        attrs: { src: "/img/icons/dialexa-icons/directions.svg" },
      }),
      _vm._v(" "),
      _c("p", [_vm._v("Pathways")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-inline-block spinner-border spinner-border-sm",
        attrs: { role: "status" },
      },
      [
        _c("span", { staticClass: "visually-hidden" }, [
          _vm._v("Segment Creating..."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h5", [_vm._v("Choose a Pathway")]),
      _vm._v(" "),
      _c("p", [_vm._v("Select a pathway")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 d-flex flex-column gap-1" }, [
      _c("img", {
        staticClass: "modal-icon",
        attrs: { src: "/img/icons/dialexa-icons/directions.svg" },
      }),
      _vm._v(" "),
      _c("p", [_vm._v("Pathways")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }