var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "column-mapping" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "transition-group",
            { attrs: { name: "table-row" } },
            _vm._l(_vm.mapping, function (column, i) {
              return _c(
                "div",
                {
                  key: column.id,
                  staticClass: "row mapping",
                  class: _vm.getMappingClass(_vm.errors.columns[i], column),
                },
                [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "card box-content my-2" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Source Column "),
                          !column.optional && column.parent != null
                            ? _c(
                                "span",
                                {
                                  staticClass: "text-secondary_v1",
                                  staticStyle: { "text-transform": "none" },
                                },
                                [_c("em", [_vm._v("(Optional)")])]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c("accordion-select", {
                              class: {
                                "is-invalid":
                                  _vm.errors.columns[i].source != "",
                              },
                              attrs: { options: _vm.src_column_array },
                              on: {
                                editing: function ($event) {
                                  return _vm.editing(column)
                                },
                                input: function ($event) {
                                  return _vm.checkHasNullValueInSource(
                                    column,
                                    i
                                  )
                                },
                              },
                              model: {
                                value: column.source_column,
                                callback: function ($$v) {
                                  _vm.$set(column, "source_column", $$v)
                                },
                                expression: "column.source_column",
                              },
                            }),
                            _vm._v(" "),
                            column.source_column == "CUSTOM" && !column.editing
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title: "Edit the Custom Logic",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editing(column)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-edit" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            column.source_column == "CUSTOM" && column.editing
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title: "Close the Custom Logic Editor",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.stopEditing(column)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-close" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            column.source_column != null &&
                            (column.source_column != "CUSTOM" ||
                              !_vm.hasAnyLogicUnitError(column))
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title: "Preview",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.previewSourceColumn(column)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-eye" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.errors.columns[i].source != ""
                          ? _c(
                              "div",
                              { staticClass: "text-danger invalid-feedback" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.errors.columns[i].source) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        column.optional
                          ? _c("div", { staticClass: "close-btn d-sm-none" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-none px-0",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeColumn(i)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-close" })]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "card box-content my-3 my-sm-2" },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Avid AI Column "),
                            !column.optional && column.parent == null
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "text-secondary_v1",
                                    staticStyle: { "text-transform": "none" },
                                  },
                                  [_c("em", [_vm._v("(Required)")])]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", [
                              column.data_type == "boolean"
                                ? _c("img", {
                                    staticClass: "icon smaller-icon me-1 mt-1",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/toggle-on-circle-gray.svg",
                                      "data-bs-toggle": "tooltip",
                                      title: "Data type: Boolean",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              column.data_type == "date"
                                ? _c("img", {
                                    staticClass: "icon smaller-icon me-1 mt-1",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/calendar.svg",
                                      "data-bs-toggle": "tooltip",
                                      title: "Data type: Date",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              column.data_type == "integer" ||
                              column.data_type == "numeric"
                                ? _c("img", {
                                    staticClass: "icon smaller-icon me-1 mt-1",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/hashtag.svg",
                                      "data-bs-toggle": "tooltip",
                                      title: "Data type: Number",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              column.data_type == "string"
                                ? _c("img", {
                                    staticClass: "icon smaller-icon me-1 mt-1",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/text.svg",
                                      "data-bs-toggle": "tooltip",
                                      title: "Data type: String",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              column.data_type == "timestamp"
                                ? _c("img", {
                                    staticClass: "icon smaller-icon me-1 mt-1",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/clock.svg",
                                      "data-bs-toggle": "tooltip",
                                      title: "Data type: Timestamp",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            !column.optional
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: column.display_name,
                                      expression: "column.display_name",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text", readonly: "" },
                                  domProps: { value: column.display_name },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        column,
                                        "display_name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : column.destination_column == null ||
                                column.destination_column.indexOf("custom") ==
                                  -1
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: column.destination_column,
                                        expression: "column.destination_column",
                                      },
                                    ],
                                    staticClass: "form-select",
                                    class: {
                                      "is-invalid":
                                        _vm.errors.columns[i].avid != "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editing(column)
                                      },
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            column,
                                            "destination_column",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.selectDestinationColumn(
                                            i,
                                            column
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "custom" } },
                                      [_vm._v("*** Custom Column ***")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.getCurrentUnusedColumns(i, column),
                                      function (val) {
                                        return _c(
                                          "option",
                                          { domProps: { value: val.column } },
                                          [_vm._v(_vm._s(val.name))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("div", { staticClass: "input-group" }, [
                                  _c(
                                    "button",
                                    {
                                      ref: "dropdown",
                                      refInFor: true,
                                      staticClass:
                                        "btn btn-secondary dropdown-toggle",
                                      class: {
                                        "is-invalid":
                                          _vm.errors.columns[i].avid.indexOf(
                                            "dropdown"
                                          ) != -1,
                                      },
                                      attrs: {
                                        type: "button",
                                        "data-bs-toggle": "dropdown",
                                        "aria-expanded": "false",
                                        id: "dropdown-" + i,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editing(column)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          column.data_type == null
                                            ? "Data Type"
                                            : _vm.getPrettyDataType(
                                                column.data_type
                                              )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-start",
                                    },
                                    [
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setDataType(
                                                  i,
                                                  "boolean"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Boolean")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setDataType(
                                                  i,
                                                  "date"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Date")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setDataType(
                                                  i,
                                                  "integer"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Integer")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setDataType(
                                                  i,
                                                  "numeric"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Decimal")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setDataType(
                                                  i,
                                                  "string"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("String")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setDataType(
                                                  i,
                                                  "timestamp"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Timestamp")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: column.display_name,
                                        expression: "column.display_name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.errors.columns[i].avid.indexOf(
                                          "name"
                                        ) != -1,
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: "Column Name",
                                    },
                                    domProps: { value: column.display_name },
                                    on: {
                                      change: function ($event) {
                                        return _vm.columnExists(
                                          i,
                                          column.display_name
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          column,
                                          "display_name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                          ]),
                          _vm._v(" "),
                          _vm.errors.columns[i].avid != ""
                            ? _c(
                                "div",
                                { staticClass: "text-danger invalid-feedback" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.errors.columns[i].avid) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          column.optional
                            ? _c(
                                "div",
                                { staticClass: "close-btn d-none d-sm-block" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-none px-0",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeColumn(i)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-close" })]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "slide" } }, [
                    column.source_column == "CUSTOM" &&
                    (column.editing ||
                      _vm.errors.columns[i].source.indexOf("logic") > -1)
                      ? _c("div", { staticClass: "mt-3 mb-2 custom-logic" }, [
                          _c("h4", [_vm._v("Custom Logic")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card box-content mb-3" }, [
                            _c("div", { staticClass: "card-body p-0" }, [
                              _c(
                                "div",
                                {
                                  ref: "accordion",
                                  refInFor: true,
                                  staticClass: "accordion",
                                  attrs: { id: "accordion-" + column.id },
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        handle: ".handle",
                                        list: column.column_logic.units,
                                        element: "div",
                                        tag: "span",
                                      },
                                      on: { end: _vm.onDragEnd },
                                    },
                                    _vm._l(
                                      column.column_logic.units,
                                      function (unit, j) {
                                        return _c(
                                          "div",
                                          { staticClass: "accordion-item" },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "accordion-header position-relative",
                                                attrs: {
                                                  id:
                                                    "heading-" +
                                                    column.id +
                                                    "-" +
                                                    j,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accordion-button",
                                                    class: {
                                                      collapsed:
                                                        j > 0 ||
                                                        _vm.errors.columns[
                                                          i
                                                        ].source.indexOf(
                                                          "logic"
                                                        ) > -1,
                                                    },
                                                    attrs: {
                                                      type: "button",
                                                      "data-bs-toggle":
                                                        "collapse",
                                                      "data-bs-target":
                                                        "#collapse-" +
                                                        column.id +
                                                        "-" +
                                                        j,
                                                      "aria-expanded":
                                                        j == 0 &&
                                                        _vm.errors.columns[
                                                          i
                                                        ].source.indexOf(
                                                          "logic"
                                                        ) == -1
                                                          ? true
                                                          : false,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(j + 1) +
                                                        ". \n                                        "
                                                    ),
                                                    column.data_type ==
                                                      "boolean" &&
                                                    (unit.value == "true" ||
                                                      unit.value == "false")
                                                      ? _c(
                                                          "em",
                                                          {
                                                            staticClass: "ms-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  unit.value ==
                                                                    "true"
                                                                    ? "Yes"
                                                                    : "No"
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "em",
                                                          {
                                                            staticClass: "ms-1",
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    unit.value_type ==
                                                                      "static" &&
                                                                      unit.value !=
                                                                        ""
                                                                      ? "&ldquo;"
                                                                      : ""
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  unit.value ==
                                                                    ""
                                                                    ? "(Not Set)"
                                                                    : _vm.getPrettyColName(
                                                                        unit.value
                                                                      )
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    unit.value_type ==
                                                                      "static" &&
                                                                      unit.value !=
                                                                        ""
                                                                      ? "&rdquo;"
                                                                      : ""
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "btn btn-none p-2 action",
                                                        staticStyle: {
                                                          right: "48px",
                                                        },
                                                        attrs: {
                                                          "data-bs-toggle":
                                                            "tooltip",
                                                          "data-placement":
                                                            "top",
                                                          title:
                                                            "Add Logic Unit",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addLogicUnit(
                                                              $event,
                                                              column,
                                                              j
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa-solid fa-plus",
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "btn btn-none p-2 action",
                                                        staticStyle: {
                                                          right: "84px",
                                                        },
                                                        attrs: {
                                                          "data-bs-toggle":
                                                            "tooltip",
                                                          "data-placement":
                                                            "top",
                                                          title: "Delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.removeLogicUnit(
                                                              $event,
                                                              column,
                                                              j
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa-solid fa-trash",
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    column.column_logic.units
                                                      .length > 1
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "btn btn-none p-2 action handle",
                                                            staticStyle: {
                                                              right: "118px",
                                                              cursor: "move",
                                                            },
                                                            attrs: {
                                                              "data-bs-toggle":
                                                                "tooltip",
                                                              "data-placement":
                                                                "top",
                                                              title:
                                                                "Move to Adjust Priority",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa-solid fa-up-down-left-right",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "accordion-collapse collapse",
                                                class: {
                                                  show:
                                                    j == 0 &&
                                                    _vm.errors.columns[
                                                      i
                                                    ].source.indexOf("logic") ==
                                                      -1,
                                                },
                                                attrs: {
                                                  id:
                                                    "collapse-" +
                                                    column.id +
                                                    "-" +
                                                    j,
                                                  "data-bs-parent":
                                                    "#accordion-" + column.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accordion-body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "col",
                                                            class: {
                                                              "col-lg-9 col-xl-8":
                                                                unit.value_type !=
                                                                "sql",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Output Value"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary dropdown-toggle",
                                                                    attrs: {
                                                                      type: "button",
                                                                      "data-bs-toggle":
                                                                        "dropdown",
                                                                      "aria-expanded":
                                                                        "false",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getValueTypeDisplay(
                                                                          unit.value_type
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-menu dropdown-menu-start",
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                unit.value_type =
                                                                                  "column"
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Column Value"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("li", [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                unit.value_type =
                                                                                  "sql"
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "SQL Value"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("li", [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                unit.value_type =
                                                                                  "static"
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Static Value"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                column.data_type ==
                                                                  "boolean" &&
                                                                unit.value_type !=
                                                                  "column"
                                                                  ? _c(
                                                                      "select",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                unit.value,
                                                                              expression:
                                                                                "unit.value",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          placeholder:
                                                                            "Value",
                                                                          id:
                                                                            "unit-text-" +
                                                                            unit.id,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal =
                                                                                Array.prototype.filter
                                                                                  .call(
                                                                                    $event
                                                                                      .target
                                                                                      .options,
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      return o.selected
                                                                                    }
                                                                                  )
                                                                                  .map(
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      var val =
                                                                                        "_value" in
                                                                                        o
                                                                                          ? o._value
                                                                                          : o.value
                                                                                      return val
                                                                                    }
                                                                                  )
                                                                              _vm.$set(
                                                                                unit,
                                                                                "value",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  "true",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Yes"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  "false",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "No"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : unit.value_type !=
                                                                    "column"
                                                                  ? _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                unit.value,
                                                                              expression:
                                                                                "unit.value",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        class: {
                                                                          "is-invalid":
                                                                            _vm
                                                                              .errors
                                                                              .columns[
                                                                              i
                                                                            ]
                                                                              .logic[
                                                                              j
                                                                            ] !=
                                                                              null &&
                                                                            _vm
                                                                              .errors
                                                                              .columns[
                                                                              i
                                                                            ]
                                                                              .logic[
                                                                              j
                                                                            ] !=
                                                                              "",
                                                                        },
                                                                        attrs: {
                                                                          type: "text",
                                                                          placeholder:
                                                                            "Value",
                                                                          id:
                                                                            "unit-text-" +
                                                                            unit.id,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              unit.value,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.sanitizeSQLStatement(
                                                                                i,
                                                                                j,
                                                                                unit
                                                                              )
                                                                            },
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.$set(
                                                                                unit,
                                                                                "value",
                                                                                $event
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "accordion-select",
                                                                      {
                                                                        class: {
                                                                          "is-invalid":
                                                                            _vm
                                                                              .errors
                                                                              .columns[
                                                                              i
                                                                            ]
                                                                              .logic[
                                                                              j
                                                                            ] !=
                                                                              null &&
                                                                            _vm
                                                                              .errors
                                                                              .columns[
                                                                              i
                                                                            ]
                                                                              .logic[
                                                                              j
                                                                            ] !=
                                                                              "",
                                                                        },
                                                                        attrs: {
                                                                          options:
                                                                            _vm.preview_column_array,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.errors.columns[
                                                                                i
                                                                              ].logic[
                                                                                j
                                                                              ] =
                                                                                ""
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            unit.value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                unit,
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "unit.value",
                                                                        },
                                                                      }
                                                                    ),
                                                                _vm._v(" "),
                                                                unit.value_type ==
                                                                "sql"
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary dropdown-toggle",
                                                                        attrs: {
                                                                          type: "button",
                                                                          "data-bs-toggle":
                                                                            "dropdown",
                                                                          "aria-expanded":
                                                                            "false",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Columns"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                unit.value_type ==
                                                                "sql"
                                                                  ? _c(
                                                                      "ul",
                                                                      {
                                                                        staticClass:
                                                                          "dropdown-menu dropdown-menu-end",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "li",
                                                                          _vm._l(
                                                                            _vm.src_columns_sorted,
                                                                            function (
                                                                              col_name,
                                                                              key
                                                                            ) {
                                                                              return _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "dropdown-item",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.addColumnToText(
                                                                                          unit.id,
                                                                                          key
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      col_name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.errors.columns[
                                                              i
                                                            ].logic[j] !=
                                                              null &&
                                                            _vm.errors.columns[
                                                              i
                                                            ].logic[j] != ""
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger invalid-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .errors
                                                                            .columns[
                                                                            i
                                                                          ]
                                                                            .logic[
                                                                            j
                                                                          ]
                                                                        ) +
                                                                        "\n                                                "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "col",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Logic Statement"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "query-inclusion-simple",
                                                              {
                                                                ref:
                                                                  "simple-" +
                                                                  i +
                                                                  "-" +
                                                                  j,
                                                                refInFor: true,
                                                                attrs: {
                                                                  client:
                                                                    _vm.client,
                                                                  static_db_columns:
                                                                    _vm.src_columns,
                                                                  is_white: false,
                                                                  source:
                                                                    _vm.source,
                                                                  data_source_id:
                                                                    _vm.data_source_id,
                                                                },
                                                                model: {
                                                                  value:
                                                                    unit.logic,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        unit,
                                                                        "logic",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "unit.logic",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "accordion-item border-top-0",
                                      staticStyle: {
                                        "border-top-left-radius": "0",
                                        "border-top-right-radius": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "h2",
                                        {
                                          staticClass: "accordion-header",
                                          attrs: { id: "default-" + column.id },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "accordion-button collapsed",
                                              staticStyle: {
                                                "border-top-left-radius": "0",
                                                "border-top-right-radius": "0",
                                              },
                                              attrs: {
                                                type: "button",
                                                "data-bs-toggle": "collapse",
                                                "data-bs-target":
                                                  "#collapse-" +
                                                  column.id +
                                                  "-default",
                                                "aria-expanded": "false",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Default: \n                                    "
                                              ),
                                              column.data_type == "boolean" &&
                                              (column.column_logic
                                                .default_value == "true" ||
                                                column.column_logic
                                                  .default_value == "false")
                                                ? _c(
                                                    "em",
                                                    { staticClass: "ms-1" },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            column.column_logic
                                                              .default_value ==
                                                              "true"
                                                              ? "Yes"
                                                              : "No"
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "em",
                                                    { staticClass: "ms-1" },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            column.column_logic
                                                              .default_type ==
                                                              "static" &&
                                                              column
                                                                .column_logic
                                                                .default_value !=
                                                                ""
                                                              ? "&ldquo;"
                                                              : ""
                                                          ),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            column.column_logic
                                                              .default_value ==
                                                              ""
                                                              ? "(Not Set)"
                                                              : column
                                                                  .column_logic
                                                                  .default_value
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            column.column_logic
                                                              .default_type ==
                                                              "static" &&
                                                              column
                                                                .column_logic
                                                                .default_value !=
                                                                ""
                                                              ? "&rdquo;"
                                                              : ""
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-none p-2 action",
                                                  staticStyle: {
                                                    right: "48px",
                                                  },
                                                  attrs: {
                                                    "data-bs-toggle": "tooltip",
                                                    "data-placement": "top",
                                                    title: "Add Logic Unit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.addLogicUnit(
                                                        $event,
                                                        column,
                                                        column.column_logic
                                                          .units.length
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa-solid fa-plus",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "accordion-collapse collapse",
                                          attrs: {
                                            id:
                                              "collapse-" +
                                              column.id +
                                              "-default",
                                            "data-bs-parent":
                                              "#accordion-" + column.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "accordion-body" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row mb-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-lg-9 col-xl-8 col",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [_vm._v("Output Value")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-secondary dropdown-toggle",
                                                              attrs: {
                                                                type: "button",
                                                                "data-bs-toggle":
                                                                  "dropdown",
                                                                "aria-expanded":
                                                                  "false",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getValueTypeDisplay(
                                                                    column
                                                                      .column_logic
                                                                      .default_type
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "dropdown-menu dropdown-menu-start",
                                                            },
                                                            [
                                                              _c("li", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-item",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          column.column_logic.default_type =
                                                                            "column"
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Column Value"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("li", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-item",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          column.column_logic.default_type =
                                                                            "sql"
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "SQL Value"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("li", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-item",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          column.column_logic.default_type =
                                                                            "static"
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Static Value"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          column.data_type ==
                                                            "boolean" &&
                                                          column.column_logic
                                                            .default_type !=
                                                            "column"
                                                            ? _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        column
                                                                          .column_logic
                                                                          .default_value,
                                                                      expression:
                                                                        "column.column_logic.default_value",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Value",
                                                                    id:
                                                                      "unit-text--default-" +
                                                                      column.id,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          column.column_logic,
                                                                          "default_value",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            "true",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Yes"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            "false",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "No"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : column
                                                                .column_logic
                                                                .default_type !=
                                                              "column"
                                                            ? _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      column
                                                                        .column_logic
                                                                        .default_value,
                                                                    expression:
                                                                      "column.column_logic.default_value",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  type: "text",
                                                                  placeholder:
                                                                    "Value",
                                                                  id: "unit-text-default",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    column
                                                                      .column_logic
                                                                      .default_value,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        column.column_logic,
                                                                        "default_value",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        column
                                                                          .column_logic
                                                                          .default_value,
                                                                      expression:
                                                                        "column.column_logic.default_value",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-select",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          column.column_logic,
                                                                          "default_value",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.src_columns,
                                                                  function (
                                                                    col_name,
                                                                    key
                                                                  ) {
                                                                    return _c(
                                                                      "option",
                                                                      {
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              key,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            col_name
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                          _vm._v(" "),
                                                          column.column_logic
                                                            .default_type ==
                                                          "sql"
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-secondary dropdown-toggle",
                                                                  attrs: {
                                                                    type: "button",
                                                                    "data-bs-toggle":
                                                                      "dropdown",
                                                                    "aria-expanded":
                                                                      "false",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Columns"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          column.column_logic
                                                            .default_type ==
                                                          "sql"
                                                            ? _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-menu dropdown-menu-end",
                                                                },
                                                                [
                                                                  _c(
                                                                    "li",
                                                                    _vm._l(
                                                                      _vm.src_columns,
                                                                      function (
                                                                        col_name,
                                                                        key
                                                                      ) {
                                                                        return _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "dropdown-item",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addColumnToText(
                                                                                    "default",
                                                                                    key
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                col_name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      column.column_logic
                                                        .default_value_error !=
                                                      ""
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-danger invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                " +
                                                                  _vm._s(
                                                                    column
                                                                      .column_logic
                                                                      .default_value_error
                                                                  ) +
                                                                  "\n                                            "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function ($event) {
                  return _vm.addColumn()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-plus" }), _vm._v(" Add Column")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary ms-3",
              on: {
                click: function ($event) {
                  return _vm.addAllColumns()
                },
              },
            },
            [
              _c("i", { staticClass: "fa-solid fa-table-columns" }),
              _vm._v(" Add All Source Columns"),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-4 d-none d-xl-block drill-down" }, [
        _c(
          "div",
          { ref: "stickyDiv", class: { "sticky-top": _vm.is_sticky } },
          _vm._l(_vm.depth, function (index) {
            return _c(
              "div",
              { key: index, staticClass: "card box-content my-2" },
              [
                _c(
                  "div",
                  {
                    staticClass: "card-body px-0 pb-0",
                    class: { "pt-0": _vm.depth != index },
                  },
                  [
                    _vm.depth == index
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col mx-3 mb-3" },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("Column to Preview"),
                              ]),
                              _vm._v(" "),
                              _c("accordion-select", {
                                attrs: { options: _vm.preview_column_array },
                                on: {
                                  input: function ($event) {
                                    return _vm.updatePreviewTable(
                                      _vm.preview_table_val[index - 1]
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.preview_table_val[index - 1],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.preview_table_val,
                                      index - 1,
                                      $$v
                                    )
                                  },
                                  expression: "preview_table_val[index-1]",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.preview_table_val[index - 1] != null && _vm.depth == 1
                      ? _c("p", { staticClass: "mx-3 mb-2" }, [
                          _vm._v(
                            "The table below represents the number of records for each of the top 10 values for the given column. Drill deeper into the data or view a preview using the icons below."
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "table-responsive",
                        staticStyle: { "max-height": "300px" },
                      },
                      [
                        _vm.depth != index
                          ? _c(
                              "div",
                              { staticClass: "overflow-hidden rounded-top" },
                              [_c("div", { staticClass: "p-1 bg-dark" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.preview_table_val[index - 1] != null
                          ? _c("table", { staticClass: "table gy-0 m-0" }, [
                              _c("thead", { staticClass: "table-dark" }, [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "ps-3",
                                      class: { "pt-0": _vm.depth != index },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("propercase")(
                                            _vm.mirrorAccordionSelectStyle(
                                              _vm.preview_table_val[index - 1]
                                            )
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    { class: { "pt-0": _vm.depth != index } },
                                    [_vm._v("Records")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      class: { "pt-0": _vm.depth != index },
                                      staticStyle: { width: "70px" },
                                    },
                                    [_vm._v("Actions")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                [
                                  _vm.preview_table[index - 1] == null
                                    ? _c("tr", [_vm._m(0, true)])
                                    : _vm.preview_table[index - 1].length == 0
                                    ? _c("tr", [_vm._m(1, true)])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.preview_table[index - 1],
                                    function (f, row_num) {
                                      return _vm.preview_table[index - 1] !=
                                        null &&
                                        (_vm.drill_index == null ||
                                          _vm.drill_index == row_num ||
                                          index > 1)
                                        ? _c("tr", { key: f.name }, [
                                            f.name != null && f.name != ""
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "ps-3 align-middle",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(f.name) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "ps-3 align-middle",
                                                  },
                                                  [_c("em", [_vm._v("null")])]
                                                ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "align-middle" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("number_with_zero")(
                                                      f.count
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-nowrap" },
                                              [
                                                _vm.depth == index
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-none p-1",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.previewRecords(
                                                              f.name
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.preview_detail_loading !=
                                                          f.name || f.name == ""
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-eye",
                                                              attrs: {
                                                                "data-bs-toggle":
                                                                  "tooltip",
                                                                "data-placement":
                                                                  "top",
                                                                title:
                                                                  "Preview",
                                                              },
                                                            })
                                                          : _c("div", {
                                                              staticClass:
                                                                "spinner-border spinner-border-sm",
                                                              attrs: {
                                                                role: "status",
                                                              },
                                                            }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.depth == 1
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-none p-1",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.drilldown(
                                                              row_num,
                                                              f.name
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa-solid fa-arrow-down",
                                                          attrs: {
                                                            "data-bs-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "top",
                                                            title: "Drill Down",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.depth == 2 && index == 1
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-none p-1",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeDrilldown()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-close",
                                                          attrs: {
                                                            "data-bs-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "top",
                                                            title:
                                                              "Remove Drill Down",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ])
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade modal",
        attrs: {
          id: "preview-modal",
          "aria-labelledby": "preview-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _vm.preview_detail_table.length > 0
            ? _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header border-bottom-0 pb-0" },
                  [
                    _c("div", { staticClass: "text-center w-100" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-none position-absolute",
                          staticStyle: { top: "10px", right: "10px" },
                          attrs: { type: "button", "data-bs-dismiss": "modal" },
                          on: {
                            click: function ($event) {
                              _vm.preview_detail_index = 0
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-close" })]
                      ),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-2 mb-1" }, [
                        _vm._v("Preview"),
                      ]),
                      _vm._v(" "),
                      _c("h5", [
                        _vm.preview_detail_index > 0 &&
                        _vm.preview_detail_table.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-none",
                                on: {
                                  click: function ($event) {
                                    _vm.preview_detail_index--
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-solid fa-chevron-left",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.preview_detail_index + 1) +
                            " of " +
                            _vm._s(_vm.preview_detail_table.length) +
                            " samples\n                            "
                        ),
                        _vm.preview_detail_index <
                        _vm.preview_detail_table.length - 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-none",
                                on: {
                                  click: function ($event) {
                                    _vm.preview_detail_index++
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa-solid fa-chevron-right",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body p-0" }, [
                  _c("table", { staticClass: "table table-striped mb-0" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { staticClass: "table-striped" },
                      _vm._l(
                        Object.values(
                          _vm.preview_detail_table[_vm.preview_detail_index]
                        ),
                        function (value, index) {
                          return _c("tr", [
                            _c("td", { staticClass: "ps-3" }, [
                              _vm._v(
                                _vm._s(_vm.getPrettyColNameByIndex(index))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(value))]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "modal-footer justify-content-start border-top-0",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", "data-bs-dismiss": "modal" },
                        on: {
                          click: function ($event) {
                            _vm.preview_detail_index = 0
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "3" } }, [
      _c("div", {
        staticClass: "spinner-border spinner-border-sm float-left",
        attrs: { role: "status" },
      }),
      _vm._v(" Loading"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "3" } }, [
      _c("strong", [
        _c("em", [_vm._v("No records were found for the given column.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-primary" }, [
      _c("tr", [
        _c("th", { staticClass: "ps-3" }, [_vm._v("Column Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Column Value")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }