var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "top-nav navbar navbar-expand-md" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("ul", { staticClass: "navbar-nav flex-row" }, [
          _vm.clients.length > 1
            ? _c(
                "li",
                {
                  staticClass:
                    "nav-item dropdown text-center mx-1 client-select",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "form-select",
                      attrs: {
                        id: "navbarDropdown",
                        href: "#",
                        type: "button",
                        "data-bs-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm.client != null
                        ? _c("span", [_vm._v(_vm._s(_vm.client.name))])
                        : _c("span", [_vm._v("Select a Client")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-start position-absolute",
                      attrs: { "aria-labelledby": "navbarDropdown" },
                    },
                    _vm._l(_vm.clients, function (c) {
                      return _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "/switch/" + c.url },
                        },
                        [_vm._v(_vm._s(c.name))]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "navbar-nav ms-auto flex-row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass:
                "nav-item dropdown text-center mx-2 mt-2 alert-dropdown icon-nav",
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle no-toggle",
                  attrs: {
                    id: "alert-dropdown",
                    href: "#",
                    type: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                    "data-bs-auto-close": "outside",
                  },
                },
                [
                  _c("img", {
                    staticClass: "icon",
                    staticStyle: { height: "24px" },
                    attrs: { src: "/img/icons/dialexa-icons/bell.svg" },
                  }),
                  _vm._v(" "),
                  _vm.notif != null && _vm.notif.length > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "position-absolute translate-middle badge rounded-pill bg-danger",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.num_notifications) +
                              "\n                        "
                          ),
                          _c("span", { staticClass: "visually-hidden" }, [
                            _vm._v("unread messages"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-end position-absolute pb-3",
                  class: _vm.notif.length <= 3 ? "pt-3" : " pt-0",
                  attrs: { "aria-labelledby": "alert-dropdown" },
                },
                [
                  _vm.notif.length > 3
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-item bg-primary text-center p-2",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-white text-d",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.dismissAll()
                                },
                              },
                            },
                            [_vm._v("Dismiss All Notifications")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    { attrs: { name: "fade-slow" } },
                    _vm._l(_vm.notif, function (n) {
                      return _c(
                        "div",
                        { key: n.id, staticClass: "dropdown-item" },
                        [
                          _c("span", { staticClass: "float-end lh-1" }, [
                            _vm._v(_vm._s(_vm._f("date")(n.created_at))),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(n.data["client_name"])),
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(n.data["error_msg"]))]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            n.data["cta"] != null
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-primary me-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.markRead(n)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(n.data["cta"]))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary btn-sm",
                                on: {
                                  click: function ($event) {
                                    return _vm.dismiss(n)
                                  },
                                },
                              },
                              [_vm._v("Dismiss")]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.notif == null || _vm.notif.length == 0
                    ? _c("div", { staticClass: "dropdown-item w-auto" }, [
                        _vm._v("No Notifications"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item dropdown text-center mx-2 mt-2 text-nav" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-end position-absolute",
                  attrs: { "aria-labelledby": "profile-avatar" },
                },
                [
                  _vm.user.user_type == "admin" ||
                  _vm.user.user_type == "agency_admin"
                    ? _c("span", [
                        _vm._m(2),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _vm._m(4),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.user_type == "admin"
                    ? _c("span", [
                        _c("div", { staticClass: "dropdown-divider" }),
                        _vm._v(" "),
                        _vm._m(5),
                        _vm._v(" "),
                        _vm._m(6),
                        _vm._v(" "),
                        _vm._m(7),
                        _vm._v(" "),
                        _c("div", { staticClass: "dropdown-divider" }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.client != null
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "/user/" + _vm.client.url + "/profile",
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-user" }),
                          _vm._v(" Edit Profile"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.client != null
                    ? _c("div", { staticClass: "dropdown-divider" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(8),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("support-modal", {
        attrs: { user: _vm.user, client: _vm.client, selected_ticket_type: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "nav-item text-center mx-2 mt-2 icon-nav" },
      [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: {
              href: "#",
              type: "button",
              "data-bs-toggle": "modal",
              "data-bs-target": "#support-modal",
            },
          },
          [
            _c("img", {
              staticClass: "icon",
              staticStyle: { width: "24px" },
              attrs: { src: "/img/icons/dialexa-icons/message-question.svg" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          id: "profile-avatar",
          href: "#",
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("span", [_vm._v("My Account")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "/admin/initiative_editor" },
      },
      [_c("i", { staticClass: "fas fa-wrench" }), _vm._v(" Initiative Editor")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "dropdown-item", attrs: { href: "/admin/manage_client" } },
      [_c("i", { staticClass: "fas fa-building" }), _vm._v(" Agency Clients")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "dropdown-item", attrs: { href: "/admin/manage_user" } },
      [_c("i", { staticClass: "fas fa-user-shield" }), _vm._v(" Agency Users")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "/insights/metrics_filters" },
      },
      [
        _c("i", { staticClass: "fa-solid fa-filter" }),
        _vm._v(" Calculations / Filters"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "dropdown-item", attrs: { href: "/insights/charts" } },
      [
        _c("i", { staticClass: "fa-solid fa-chart-simple" }),
        _vm._v(" Chart Builder"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: { href: "/insights/report_builder" },
      },
      [_c("i", { staticClass: "fa-solid fa-book" }), _vm._v(" Report Builder")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item",
        attrs: {
          href: "/logout",
          onclick:
            "event.preventDefault(); document.getElementById('logout-form').submit();",
        },
      },
      [
        _c("i", { staticClass: "fas fa-sign-out-alt" }),
        _vm._v(" Logout\n                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }