var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-2",
      attrs: { id: "org_settings_connections-view-dataset" },
    },
    [
      _c(
        "div",
        { attrs: { id: "org_settings_connections-view-dataset__text" } },
        [
          _c("div", { staticClass: "d-flex flex-column gap-1 flex-grow-1" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "system-connection-card__header row" }, [
                _vm.local_connection
                  ? _c("div", { staticClass: "col-12 d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "system-connection-card__header__icon" },
                        [
                          _c("img", {
                            staticClass:
                              "avatar rounded-circle avatar-sm me-2 bg-white",
                            attrs: {
                              src: _vm.local_connection.integration.logo_url,
                              alt: _vm.local_connection.integration.name,
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", { staticClass: "mt-1" }, [
                        _vm._v(
                          _vm._s(_vm.local_connection.integration.display_name)
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "system-connection-card__header__status ms-2",
                        },
                        [
                          !_vm.local_connection.sync_error_msg &&
                          !_vm.local_connection.last_synced_at &&
                          !_vm.local_connection.initial_sync_at
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-secondary d-inline-block",
                                },
                                [_vm._v("Initializing")]
                              )
                            : _vm.local_connection.sync_error_msg
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-error d-inline-block",
                                  attrs: {
                                    "data-bs-toggle": "tooltip",
                                    title: _vm.local_connection.sync_error_msg,
                                  },
                                },
                                [_vm._v("Error")]
                              )
                            : _vm.local_connection.active_jobs.length > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-success-dark d-inline-block",
                                },
                                [_vm._v("Syncing")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-success d-inline-block",
                                },
                                [_vm._v("Active")]
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.canSync() || _vm.canDelete()
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "system-connection-card__header__actions",
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("div", { staticClass: "dropdown-menu" }, [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "#",
                                        "data-bs-toggle": "modal",
                                        "data-bs-target": "#system-modal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.updateConnection()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-btn fa-key",
                                      }),
                                      _vm._v(
                                        "Update Credentials\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.canSync()
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.syncConnection()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-btn fa-sync me-2",
                                          }),
                                          _vm._v("Sync Now"),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canDelete()
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteConnection()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-btn fa-trash me-2",
                                          }),
                                          _vm._v("Delete"),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.local_file
                  ? _c("div", { staticClass: "col-12 d-flex" }, [
                      _c("h4", { staticClass: "mt-1" }, [
                        _vm._v(_vm._s(_vm.local_file.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "system-connection-card__header__status ms-2",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "badge badge-success d-inline-block",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("propercase")(_vm.local_file.status)
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "system-connection-card__header__actions",
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-menu" }, [
                            _vm.isDataFile(_vm.local_file)
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href: "#",
                                        "data-bs-toggle": "modal",
                                        "data-bs-target": "#preview-modal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewTable(
                                            _vm.local_file
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/eye.svg",
                                        },
                                      }),
                                      _vm._v(
                                        " Preview File\n                                "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.withinThirtyDays(_vm.local_file)
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href:
                                          "/integrations/" +
                                          _vm.client.url +
                                          "/files/download/" +
                                          _vm.local_file.id,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "/img/icons/dialexa-icons/download.svg",
                                        },
                                      }),
                                      _vm._v(
                                        " Download File\n                                "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#", target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile(_vm.local_file)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: "/img/icons/dialexa-icons/trash.svg",
                                    },
                                  }),
                                  _vm._v(
                                    " Delete File\n                                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "system-connection-card__content" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-items-center" },
                  [
                    _c("div", { staticClass: "d-flex-flex-column" }, [
                      _vm.local_connection
                        ? _c("small", [
                            _vm._v(
                              _vm._s(_vm.local_connection.name) +
                                "   |   Last Synced: " +
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.local_connection.last_synced_at
                                  )
                                ) +
                                "    |    Created on: " +
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.local_connection.created_at
                                  )
                                )
                            ),
                          ])
                        : _c("small", [
                            _vm._v(
                              _vm._s(_vm.local_file.source) +
                                "   |   Uploaded date: " +
                                _vm._s(
                                  _vm._f("date")(_vm.local_file.created_at)
                                ) +
                                " "
                            ),
                          ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.local_connection && _vm.local_connection.sync_error_msg
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "alert border-danger border-primary mt-4 mb-4",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("h5", [_vm._v("Integration Error")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "Avid encountered an error with your " +
                                  _vm._s(
                                    _vm.local_connection.integration
                                      .display_name
                                  ) +
                                  " connection. To reactivate the integration, you simply need to click the button below and follow the steps listed."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 mt-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-error",
                                attrs: {
                                  "data-bs-toggle": "modal",
                                  "data-bs-target": "#system-modal",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.updateConnection()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-btn fa-key text-danger",
                                }),
                                _vm._v(
                                  " Reconnect " +
                                    _vm._s(
                                      _vm.local_connection.integration
                                        .display_name
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "alert bg-secondary border-primary mt-3 mb-4",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 mt-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                attrs: {
                                  "data-bs-toggle": "modal",
                                  "data-bs-target":
                                    "#support-modal-" +
                                    _vm.selected_ticket_type,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openSupportModal()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon pe-2",
                                  attrs: {
                                    src: "/img/icons/dialexa-icons/glasses.svg",
                                  },
                                }),
                                _vm._v(
                                  " Help Me Set Up My Data (* this is a free service)\n                                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                _vm._v(" "),
                _vm.local_datasets && _vm.local_datasets.length > 0
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("p", [
                          _vm._v(
                            'A "data set" is a view of the data extracted from your integrated systems. Below you can add filters to these views to limit the data available for analysis and syncronization to third party systems.'
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.local_datasets.length <= 3
                          ? _c(
                              "nav",
                              {
                                staticClass:
                                  "navbar navbar-expand secondary-tab-bar p-0 mb-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "container-fluid px-0 mb-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "collapse navbar-collapse",
                                        attrs: { id: "dataset-navbar" },
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "navbar-nav mb-2 mb-lg-0",
                                          },
                                          _vm._l(
                                            _vm.local_datasets,
                                            function (dataset, index) {
                                              return _c(
                                                "li",
                                                { staticClass: "nav-item" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "nav-link d-flex flex-nowrap secondary-tab",
                                                      class: {
                                                        "secondary-tab-active":
                                                          index ==
                                                          _vm.active_tab_index,
                                                      },
                                                      attrs: {
                                                        href: "#",
                                                        role: "button",
                                                        "aria-expanded":
                                                          "false",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.setActiveTab(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("h6", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "propercase"
                                                            )(dataset.name)
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("div", { staticClass: "col-12 mb-3 mt-3" }, [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("Select a Data Set to Edit"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.active_tab_index,
                                      expression: "active_tab_index",
                                    },
                                  ],
                                  staticClass: "form-select",
                                  attrs: { name: "dataset-selection" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.active_tab_index = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        return _vm.changeDatasetSelection()
                                      },
                                    ],
                                  },
                                },
                                _vm._l(
                                  _vm.local_datasets,
                                  function (dataset, index) {
                                    return _c(
                                      "option",
                                      { domProps: { value: index } },
                                      [_vm._v(_vm._s(dataset.name))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                        _vm._v(" "),
                        _vm._l(_vm.local_datasets, function (dataset, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "row",
                              class:
                                index == _vm.active_tab_index ? "" : "d-none",
                            },
                            [
                              _c("label", { staticClass: "form-label mb-0" }, [
                                _vm._v("Data Set Name"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 mb-2" }, [
                                !_vm.if_editing_dataset_name
                                  ? _c("h5", [
                                      _vm._v(
                                        _vm._s(dataset.name) +
                                          "\n                                    "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-none",
                                          attrs: {
                                            "data-bs-toggle": "tooltip",
                                            title: "Edit Dataset Name",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toggleEditName()
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "icon",
                                            attrs: {
                                              src: "/img/icons/dialexa-icons/pencil.svg",
                                            },
                                          }),
                                        ]
                                      ),
                                    ])
                                  : _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flex-row gap-1",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.local_datasets[index]
                                                    .name,
                                                expression:
                                                  "local_datasets[index].name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class:
                                              _vm.errors.name != ""
                                                ? "is-invalid"
                                                : "",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value:
                                                _vm.local_datasets[index].name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.local_datasets[index],
                                                  "name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary reg-btn",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.saveDataSetName(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-save m-0",
                                                attrs: {
                                                  "data-bs-toggle": "tooltip",
                                                  title: "Save Data Set Name",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-secondary",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.cancelEditName(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-close m-0",
                                                attrs: {
                                                  "data-bs-toggle": "tooltip",
                                                  title: "Cancel Editing",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.errors.name != ""
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-danger invalid-feedback",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.errors.name) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                              ]),
                              _vm._v(" "),
                              dataset.source_type != "other" ||
                              dataset.source_subtype != null
                                ? _c("div", { staticClass: "col-3" }, [
                                    dataset.is_primary == 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary float-end mb-2",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updateIsPrimary(
                                                  dataset
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: "/img/icons/dialexa-icons/1st-medal.svg",
                                              },
                                            }),
                                            _vm._v(" Set As Primary Data Set "),
                                            _c("i", {
                                              staticClass: "fas fa-info-circle",
                                              attrs: {
                                                "data-bs-toggle": "tooltip",
                                                title:
                                                  "Certain analyses require a single source to be used in the analysis. Setting the primary flag identifies which source to use.",
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "float-end mt-2" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-check fa-xl green",
                                            }),
                                            _vm._v(
                                              " Primary Data Set\n                                        "
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-info-circle ms-1",
                                              attrs: {
                                                "data-bs-toggle": "tooltip",
                                                title:
                                                  "Certain analyses require a single source to be used in the analysis. Setting the primary flag identifies which source to use.",
                                              },
                                            }),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "transition-group",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-content-start gap-3",
                                      attrs: { tag: "div" },
                                    },
                                    [
                                      _vm.file_or_connection == "file" ||
                                      _vm.isFileConnection()
                                        ? _c(
                                            "div",
                                            {
                                              key: "dataset-file-information",
                                              staticClass: "accordion",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "accordion-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accordion-header",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "accordion-button collapsed",
                                                          attrs: {
                                                            type: "button",
                                                            "data-bs-target":
                                                              "#dataset-file-information",
                                                            "data-bs-toggle":
                                                              "collapse",
                                                            "aria-expanded":
                                                              "false",
                                                            "aria-controls":
                                                              "#dataset-file-information",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Information"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accordion-body accordion-collapse pt-0",
                                                      class:
                                                        _vm.if_expand_file_information
                                                          ? ""
                                                          : "collapse",
                                                      attrs: {
                                                        id: "dataset-file-information",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col",
                                                            },
                                                            [
                                                              dataset.source !=
                                                                "Airbyte" &&
                                                              dataset.source !=
                                                                "Table"
                                                                ? _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            staticClass:
                                                                              "form-label",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Refresh Type"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "select",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    dataset
                                                                                      .settings
                                                                                      .refresh_type,
                                                                                  expression:
                                                                                    "dataset.settings.refresh_type",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-select",
                                                                            on: {
                                                                              change:
                                                                                [
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$selectedVal =
                                                                                      Array.prototype.filter
                                                                                        .call(
                                                                                          $event
                                                                                            .target
                                                                                            .options,
                                                                                          function (
                                                                                            o
                                                                                          ) {
                                                                                            return o.selected
                                                                                          }
                                                                                        )
                                                                                        .map(
                                                                                          function (
                                                                                            o
                                                                                          ) {
                                                                                            var val =
                                                                                              "_value" in
                                                                                              o
                                                                                                ? o._value
                                                                                                : o.value
                                                                                            return val
                                                                                          }
                                                                                        )
                                                                                    _vm.$set(
                                                                                      dataset.settings,
                                                                                      "refresh_type",
                                                                                      $event
                                                                                        .target
                                                                                        .multiple
                                                                                        ? $$selectedVal
                                                                                        : $$selectedVal[0]
                                                                                    )
                                                                                  },
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.columnsValid()
                                                                                  },
                                                                                ],
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "option",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      "incremental",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Append: New files are merged with prior uploads"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "option",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      "full",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Replace: New files replace prior uploads"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        dataset
                                                                          .settings
                                                                          .refresh_type ==
                                                                        "incremental"
                                                                          ? _c(
                                                                              "em",
                                                                              {
                                                                                staticClass:
                                                                                  "small",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  'When appending files, the "Updated At" field will ensure the most recent record is used (if it is set on the Column Mapping screen).'
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    dataset
                                                                      .settings
                                                                      .refresh_type ==
                                                                    "incremental"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mb-3",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                                attrs:
                                                                                  {
                                                                                    "data-bs-toggle":
                                                                                      "tooltip",
                                                                                    title:
                                                                                      "When appending files, you can select one or more columns to be used as a unique ID. Records with the same ID as a record in a prior upload will be used as a replacement rather than a new record. If no columns are selected, records will always be appended.",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Column(s) to Identify Unique Records "
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fas fa-info-circle",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "dropdown",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "button",
                                                                                  {
                                                                                    staticClass:
                                                                                      "btn form-select btn-field",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "button",
                                                                                        id: "dropdownMenuButton",
                                                                                        "data-bs-toggle":
                                                                                          "dropdown",
                                                                                        "aria-haspopup":
                                                                                          "true",
                                                                                        "aria-expanded":
                                                                                          "false",
                                                                                        "data-bs-auto-close":
                                                                                          "outside",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    dataset
                                                                                      .settings
                                                                                      .id_columns !=
                                                                                      null &&
                                                                                    dataset
                                                                                      .settings
                                                                                      .id_columns
                                                                                      .length >
                                                                                      0
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.id_columns
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _c(
                                                                                          "span",
                                                                                          [
                                                                                            _c(
                                                                                              "em",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "None Selected"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "dropdown-menu",
                                                                                    attrs:
                                                                                      {
                                                                                        "aria-labelledby":
                                                                                          "dropdownMenuButton",
                                                                                      },
                                                                                  },
                                                                                  _vm._l(
                                                                                    dataset.source_columns,
                                                                                    function (
                                                                                      item,
                                                                                      key
                                                                                    ) {
                                                                                      return _c(
                                                                                        "label",
                                                                                        {
                                                                                          key: key,
                                                                                          staticClass:
                                                                                            "dropdown-item",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      dataset
                                                                                                        .settings
                                                                                                        .id_columns,
                                                                                                    expression:
                                                                                                      "dataset.settings.id_columns",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "form-check-input",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "checkbox",
                                                                                                  "true-value":
                                                                                                    [],
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    key,
                                                                                                  checked:
                                                                                                    Array.isArray(
                                                                                                      dataset
                                                                                                        .settings
                                                                                                        .id_columns
                                                                                                    )
                                                                                                      ? _vm._i(
                                                                                                          dataset
                                                                                                            .settings
                                                                                                            .id_columns,
                                                                                                          key
                                                                                                        ) >
                                                                                                        -1
                                                                                                      : _vm._q(
                                                                                                          dataset
                                                                                                            .settings
                                                                                                            .id_columns,
                                                                                                          []
                                                                                                        ),
                                                                                                },
                                                                                              on: {
                                                                                                change:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    var $$a =
                                                                                                        dataset
                                                                                                          .settings
                                                                                                          .id_columns,
                                                                                                      $$el =
                                                                                                        $event.target,
                                                                                                      $$c =
                                                                                                        $$el.checked
                                                                                                          ? []
                                                                                                          : false
                                                                                                    if (
                                                                                                      Array.isArray(
                                                                                                        $$a
                                                                                                      )
                                                                                                    ) {
                                                                                                      var $$v =
                                                                                                          key,
                                                                                                        $$i =
                                                                                                          _vm._i(
                                                                                                            $$a,
                                                                                                            $$v
                                                                                                          )
                                                                                                      if (
                                                                                                        $$el.checked
                                                                                                      ) {
                                                                                                        $$i <
                                                                                                          0 &&
                                                                                                          _vm.$set(
                                                                                                            dataset.settings,
                                                                                                            "id_columns",
                                                                                                            $$a.concat(
                                                                                                              [
                                                                                                                $$v,
                                                                                                              ]
                                                                                                            )
                                                                                                          )
                                                                                                      } else {
                                                                                                        $$i >
                                                                                                          -1 &&
                                                                                                          _vm.$set(
                                                                                                            dataset.settings,
                                                                                                            "id_columns",
                                                                                                            $$a
                                                                                                              .slice(
                                                                                                                0,
                                                                                                                $$i
                                                                                                              )
                                                                                                              .concat(
                                                                                                                $$a.slice(
                                                                                                                  $$i +
                                                                                                                    1
                                                                                                                )
                                                                                                              )
                                                                                                          )
                                                                                                      }
                                                                                                    } else {
                                                                                                      _vm.$set(
                                                                                                        dataset.settings,
                                                                                                        "id_columns",
                                                                                                        $$c
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "ms-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                                                        " +
                                                                                                  _vm._s(
                                                                                                    item
                                                                                                  ) +
                                                                                                  "\n                                                                        "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            staticClass:
                                                                              "form-label",
                                                                            attrs:
                                                                              {
                                                                                "data-bs-toggle":
                                                                                  "tooltip",
                                                                                title:
                                                                                  "Identify what files should be included in this data set. You can use a wildcard character (%) if there are parts of the file name that will change with each upload or, for more advanced rules, use a regular expression by starting the pattern with r' and ending it with a '",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "File Name Pattern "
                                                                            ),
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fas fa-info-circle",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    dataset
                                                                                      .settings
                                                                                      .pattern,
                                                                                  expression:
                                                                                    "dataset.settings.pattern",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-control",
                                                                            class:
                                                                              _vm
                                                                                .errors
                                                                                .pattern !=
                                                                              ""
                                                                                ? "is-invalid"
                                                                                : "",
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                name: "pattern",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  dataset
                                                                                    .settings
                                                                                    .pattern,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.refreshFiles()
                                                                                },
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  )
                                                                                    return
                                                                                  _vm.$set(
                                                                                    dataset.settings,
                                                                                    "pattern",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm
                                                                          .errors
                                                                          .pattern !=
                                                                        ""
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger invalid-feedback",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                    " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .errors
                                                                                        .pattern
                                                                                    ) +
                                                                                    "\n                                                                "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "form-check",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        dataset
                                                                                          .settings
                                                                                          .ignore_unused,
                                                                                      expression:
                                                                                        "dataset.settings.ignore_unused",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-check-input",
                                                                                attrs:
                                                                                  {
                                                                                    type: "checkbox",
                                                                                    id: "ignore_unused",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    checked:
                                                                                      Array.isArray(
                                                                                        dataset
                                                                                          .settings
                                                                                          .ignore_unused
                                                                                      )
                                                                                        ? _vm._i(
                                                                                            dataset
                                                                                              .settings
                                                                                              .ignore_unused,
                                                                                            null
                                                                                          ) >
                                                                                          -1
                                                                                        : dataset
                                                                                            .settings
                                                                                            .ignore_unused,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    [
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        var $$a =
                                                                                            dataset
                                                                                              .settings
                                                                                              .ignore_unused,
                                                                                          $$el =
                                                                                            $event.target,
                                                                                          $$c =
                                                                                            $$el.checked
                                                                                              ? true
                                                                                              : false
                                                                                        if (
                                                                                          Array.isArray(
                                                                                            $$a
                                                                                          )
                                                                                        ) {
                                                                                          var $$v =
                                                                                              null,
                                                                                            $$i =
                                                                                              _vm._i(
                                                                                                $$a,
                                                                                                $$v
                                                                                              )
                                                                                          if (
                                                                                            $$el.checked
                                                                                          ) {
                                                                                            $$i <
                                                                                              0 &&
                                                                                              _vm.$set(
                                                                                                dataset.settings,
                                                                                                "ignore_unused",
                                                                                                $$a.concat(
                                                                                                  [
                                                                                                    $$v,
                                                                                                  ]
                                                                                                )
                                                                                              )
                                                                                          } else {
                                                                                            $$i >
                                                                                              -1 &&
                                                                                              _vm.$set(
                                                                                                dataset.settings,
                                                                                                "ignore_unused",
                                                                                                $$a
                                                                                                  .slice(
                                                                                                    0,
                                                                                                    $$i
                                                                                                  )
                                                                                                  .concat(
                                                                                                    $$a.slice(
                                                                                                      $$i +
                                                                                                        1
                                                                                                    )
                                                                                                  )
                                                                                              )
                                                                                          }
                                                                                        } else {
                                                                                          _vm.$set(
                                                                                            dataset.settings,
                                                                                            "ignore_unused",
                                                                                            $$c
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.columnsValid()
                                                                                      },
                                                                                    ],
                                                                                },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "form-check-label",
                                                                                attrs:
                                                                                  {
                                                                                    for: "ignore_unused",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "strong",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Ignore Unused Column Changes."
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " If columns are added or removed in future uploads, the file will still process when the changes don't impact existing mappings.\n                                                                "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          dataset.source !=
                                                            "Airbyte" &&
                                                          dataset.source !=
                                                            "Table"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "card box-content mb-3 overflow-hidden",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "card-body px-0 pb-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "px-3 pb-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Based upon the pattern specified, the following files will be included in this data set:"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm
                                                                            .local_files
                                                                            .length >
                                                                          0
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "table-responsive",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "max-height":
                                                                                        "271px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "table",
                                                                                    {
                                                                                      staticClass:
                                                                                        "table gy-0 m-0 mb-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "thead",
                                                                                        {
                                                                                          staticClass:
                                                                                            "table-dark",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "tr",
                                                                                            [
                                                                                              _c(
                                                                                                "th",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "align-middle",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "File Name"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "th",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "align-middle",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      colspan:
                                                                                                        "2",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Uploaded At"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "transition-group",
                                                                                        {
                                                                                          staticClass:
                                                                                            "table-border-bottom-0",
                                                                                          attrs:
                                                                                            {
                                                                                              tag: "tbody",
                                                                                              name: "table-row",
                                                                                            },
                                                                                        },
                                                                                        _vm._l(
                                                                                          _vm.local_files,
                                                                                          function (
                                                                                            f,
                                                                                            index
                                                                                          ) {
                                                                                            return _c(
                                                                                              "tr",
                                                                                              {
                                                                                                key: f.id,
                                                                                                class:
                                                                                                  f.status_msg ==
                                                                                                  "MISMATCH"
                                                                                                    ? "error"
                                                                                                    : "",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ps-3 align-middle",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                                    " +
                                                                                                        _vm._s(
                                                                                                          f.name
                                                                                                        ) +
                                                                                                        "\n                                                                                "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "align-middle text-nowrap",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        colspan:
                                                                                                          f.status_msg ==
                                                                                                          "MISMATCH"
                                                                                                            ? "1"
                                                                                                            : "2",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm._f(
                                                                                                          "datetime"
                                                                                                        )(
                                                                                                          f.created_at
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                f.status_msg ==
                                                                                                "MISMATCH"
                                                                                                  ? _c(
                                                                                                      "td",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "text-end",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "button",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "btn btn-sm btn-none red",
                                                                                                            attrs:
                                                                                                              {
                                                                                                                type: "button",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "i",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "fa fa-trash",
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.removeFile(
                                                                                                                        f
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              }
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        0
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "p",
                                                                                    {
                                                                                      staticClass:
                                                                                        "p-3",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "strong",
                                                                                        [
                                                                                          _c(
                                                                                            "em",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "No files were found that match the given pattern."
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col",
                                                            },
                                                            [
                                                              _vm
                                                                .information_form
                                                                .error
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "alert alert-danger mb-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                                There is an issue with the information provided in the form above. Please check the fields and make the necessary corrections.\n                                                            "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mb-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-primary",
                                                                      attrs: {
                                                                        type: "submit",
                                                                        disabled:
                                                                          _vm
                                                                            .information_form
                                                                            .busy,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.saveInformation(
                                                                              dataset,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .information_form
                                                                        .busy
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "spinner-border spinner-border-sm float-left ms-1",
                                                                              attrs:
                                                                                {
                                                                                  role: "status",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-btn fa-save me-1",
                                                                            }
                                                                          ),
                                                                      _vm._v(
                                                                        " Save \n                                                                "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          key: "dataset-inclusion-filters",
                                          staticClass: "accordion",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "accordion-item" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "accordion-header",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accordion-button collapsed",
                                                      attrs: {
                                                        type: "button",
                                                        "data-bs-target":
                                                          "#dataset-inclusion-filters",
                                                        "data-bs-toggle":
                                                          "collapse",
                                                        "aria-expanded":
                                                          "false",
                                                        "aria-controls":
                                                          "#dataset-inclusion-filters",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            "Inclusion Filters"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "accordion-body accordion-collapse pt-0",
                                                  class:
                                                    _vm.if_expand_inclusion_filters
                                                      ? ""
                                                      : "collapse",
                                                  attrs: {
                                                    id: "dataset-inclusion-filters",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col mb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class:
                                                                dataset.filter !=
                                                                  null &&
                                                                dataset.filter
                                                                  .filter_ui ==
                                                                  "advanced"
                                                                  ? "card box-content"
                                                                  : "",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class: {
                                                                    "card-body":
                                                                      dataset.filter !=
                                                                        null &&
                                                                      dataset
                                                                        .filter
                                                                        .filter_ui ==
                                                                        "advanced",
                                                                  },
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      "You are able to limit the records in your data set. This is helpful if removing records like soft credits from a list of transactions or non-donors from a list of constituents."
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "query-inclusion-builder",
                                                                    {
                                                                      ref: "filter",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        client:
                                                                          _vm.client,
                                                                        static_db_columns:
                                                                          dataset.source_columns,
                                                                        include_trans: false,
                                                                        source:
                                                                          dataset.data_source,
                                                                        in_card: false,
                                                                        add_blank: false,
                                                                        data_source_id:
                                                                          dataset.id,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          dataset.filter,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              dataset,
                                                                              "filter",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "dataset.filter",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "col" },
                                                        [
                                                          _vm
                                                            .inclusion_filter_form
                                                            .error
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "alert alert-danger mb-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            There is an issue with the information provided in the fields above. Please check your entries and make the necessary corrections.\n                                                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-primary",
                                                                  attrs: {
                                                                    type: "submit",
                                                                    disabled:
                                                                      _vm
                                                                        .inclusion_filter_form
                                                                        .busy,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.saveInclusionFilters(
                                                                          dataset,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .inclusion_filter_form
                                                                    .busy
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "spinner-border spinner-border-sm float-left ms-1",
                                                                          attrs:
                                                                            {
                                                                              role: "status",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c("i", {
                                                                        staticClass:
                                                                          "fa fa-btn fa-save me-1",
                                                                      }),
                                                                  _vm._v(
                                                                    " Save \n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          key: "dataset-column-mappings",
                                          staticClass: "accordion",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "accordion-item" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "accordion-header",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accordion-button collapsed",
                                                      attrs: {
                                                        type: "button",
                                                        "data-bs-target":
                                                          "#dataset-column-mappings",
                                                        "data-bs-toggle":
                                                          "collapse",
                                                        "aria-expanded":
                                                          "false",
                                                        "aria-controls":
                                                          "#dataset-column-mappings",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            "Column Mappings"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "accordion-body accordion-collapse pt-0",
                                                  class:
                                                    _vm.if_expand_column_mappings
                                                      ? ""
                                                      : "collapse",
                                                  attrs: {
                                                    id: "dataset-column-mappings",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col mb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mb-3",
                                                            },
                                                            [
                                                              _vm._v(
                                                                'Based upon the "Data Category" selected, there are several mandatory and optional fields to be mapped. '
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "If you have additional columns you'd like to use for filters, be sure to add those columns too."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.if_dest_columns_loaded
                                                            ? _c(
                                                                "column-mapping",
                                                                {
                                                                  key: dataset.id,
                                                                  ref: "column_mapping",
                                                                  refInFor: true,
                                                                  attrs: {
                                                                    id:
                                                                      "dataset-column-mappings-" +
                                                                      dataset.id,
                                                                    client:
                                                                      _vm.client,
                                                                    dataset:
                                                                      dataset,
                                                                    dest_columns:
                                                                      dataset.dest_columns,
                                                                    data_source_id:
                                                                      dataset.id,
                                                                    source:
                                                                      dataset.data_source,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      dataset.columns,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          dataset,
                                                                          "columns",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dataset.columns",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "col" },
                                                        [
                                                          _vm
                                                            .column_mapping_form
                                                            .error
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "alert alert-danger mb-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            There was an issue saving the data set. Check the fields above to get more information.\n                                                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-primary",
                                                                  attrs: {
                                                                    type: "submit",
                                                                    disabled:
                                                                      _vm
                                                                        .column_mapping_form
                                                                        .busy,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.saveColumnMapping(
                                                                          dataset,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .column_mapping_form
                                                                    .busy
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "spinner-border spinner-border-sm float-left ms-1",
                                                                          attrs:
                                                                            {
                                                                              role: "status",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c("i", {
                                                                        staticClass:
                                                                          "fa fa-btn fa-save me-1",
                                                                      }),
                                                                  _vm._v(
                                                                    " Save \n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          key: "dataset-details-segments",
                                          staticClass: "accordion",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "accordion-item" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "accordion-header",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accordion-button collapsed",
                                                      attrs: {
                                                        type: "button",
                                                        "data-bs-target":
                                                          "#dataset-details-segments",
                                                        "data-bs-toggle":
                                                          "collapse",
                                                        "aria-expanded":
                                                          "false",
                                                        "aria-controls":
                                                          "#dataset-details-segments",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            "Segments (" +
                                                              _vm._s(
                                                                dataset.segments
                                                                  ? dataset
                                                                      .segments
                                                                      .length
                                                                  : 0
                                                              ) +
                                                              ")"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "accordion-body accordion-collapse pt-0",
                                                  class:
                                                    _vm.if_expand_segment_section
                                                      ? ""
                                                      : "collapse",
                                                  attrs: {
                                                    id: "dataset-details-segments",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column gap-2",
                                                    },
                                                    [
                                                      dataset.segments &&
                                                      (dataset.segments.length >
                                                        0 ||
                                                        (dataset.segments
                                                          .length == 0 &&
                                                          _vm.search_key != ""))
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-100 card card-has-table",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "card-header",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row justify-content-between gap-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "position-relative flex-grow-1",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.search_key,
                                                                                    expression:
                                                                                      "search_key",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "search-box form-control",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                  placeholder:
                                                                                    "Search by Segment Name...",
                                                                                  "aria-label":
                                                                                    "Search by Segment Name...",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    _vm.search_key,
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.search_key =
                                                                                        $event.target.value
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.searchSegment(
                                                                                        index
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.search_key ==
                                                                          ""
                                                                            ? _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-search search-placeholder-icon",
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-start align-items-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary float-end",
                                                                              attrs:
                                                                                {
                                                                                  href:
                                                                                    "/segment/" +
                                                                                    _vm
                                                                                      .client
                                                                                      .url +
                                                                                    "/new?dataset=" +
                                                                                    dataset.id,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fas fa-fw fa-btn fa-plus",
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Add Segment\n                                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "card-body p-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "table",
                                                                    {
                                                                      staticClass:
                                                                        "table table-responsive table-with-pagination m-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "thead",
                                                                        [
                                                                          _c(
                                                                            "tr",
                                                                            {
                                                                              staticClass:
                                                                                "table-header-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.sortByKey(
                                                                                          "name",
                                                                                          dataset
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex flex-row",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                Segment\n                                                                                "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-column",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "img",
                                                                                            {
                                                                                              staticClass:
                                                                                                "sort-key",
                                                                                              class:
                                                                                                _vm.sort_key ==
                                                                                                  "name" &&
                                                                                                _vm.sort_order ==
                                                                                                  "asc"
                                                                                                  ? "sort-key-active"
                                                                                                  : "",
                                                                                              attrs:
                                                                                                {
                                                                                                  src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "img",
                                                                                            {
                                                                                              staticClass:
                                                                                                "sort-key",
                                                                                              class:
                                                                                                _vm.sort_key ==
                                                                                                  "name" &&
                                                                                                _vm.sort_order ==
                                                                                                  "des"
                                                                                                  ? "sort-key-active"
                                                                                                  : "",
                                                                                              attrs:
                                                                                                {
                                                                                                  src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.sortByKey(
                                                                                          "size",
                                                                                          dataset
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex flex-row",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                Contacts\n                                                                                "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-column",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "img",
                                                                                            {
                                                                                              staticClass:
                                                                                                "sort-key",
                                                                                              class:
                                                                                                _vm.sort_key ==
                                                                                                  "size" &&
                                                                                                _vm.sort_order ==
                                                                                                  "asc"
                                                                                                  ? "sort-key-active"
                                                                                                  : "",
                                                                                              attrs:
                                                                                                {
                                                                                                  src: "/img/icons/dialexa-icons/chevron-up.svg",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "img",
                                                                                            {
                                                                                              staticClass:
                                                                                                "sort-key",
                                                                                              class:
                                                                                                _vm.sort_key ==
                                                                                                  "size" &&
                                                                                                _vm.sort_order ==
                                                                                                  "des"
                                                                                                  ? "sort-key-active"
                                                                                                  : "",
                                                                                              attrs:
                                                                                                {
                                                                                                  src: "/img/icons/dialexa-icons/chevron-down.svg",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "120px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Action"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "tbody",
                                                                        [
                                                                          dataset
                                                                            .segments
                                                                            .length ==
                                                                            0 &&
                                                                          _vm.search_key !=
                                                                            ""
                                                                            ? _c(
                                                                                "tr",
                                                                                {
                                                                                  staticClass:
                                                                                    "table-row-1 no-results-text",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "align-start",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                            There is no file match the given search.\n                                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._l(
                                                                                dataset.segments,
                                                                                function (
                                                                                  segment,
                                                                                  i
                                                                                ) {
                                                                                  return _c(
                                                                                    "tr",
                                                                                    {
                                                                                      class: `table-row-${
                                                                                        i +
                                                                                        1
                                                                                      }`,
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "align-start vertical-align-center",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                            " +
                                                                                              _vm._s(
                                                                                                segment.name
                                                                                              ) +
                                                                                              "\n                                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "vertical-align-center",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                            " +
                                                                                              _vm._s(
                                                                                                _vm._f(
                                                                                                  "number_with_zero"
                                                                                                )(
                                                                                                  segment.size
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "align-start show-on-row-hover d-flex",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "a",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn btn-none",
                                                                                              attrs:
                                                                                                {
                                                                                                  href:
                                                                                                    "/segment/" +
                                                                                                    _vm
                                                                                                      .client
                                                                                                      .url +
                                                                                                    "/clone/" +
                                                                                                    segment.id,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "icon",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      src: "/img/icons/dialexa-icons/document-copy.svg",
                                                                                                      "data-bs-toggle":
                                                                                                        "tooltip",
                                                                                                      "data-bs-placement":
                                                                                                        "bottom",
                                                                                                      title:
                                                                                                        "Copy Segment",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "a",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn btn-none",
                                                                                              attrs:
                                                                                                {
                                                                                                  href:
                                                                                                    "/segment/" +
                                                                                                    _vm
                                                                                                      .client
                                                                                                      .url +
                                                                                                    "/" +
                                                                                                    segment.id,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "icon",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      src: "/img/icons/dialexa-icons/pencil.svg",
                                                                                                      "data-bs-toggle":
                                                                                                        "tooltip",
                                                                                                      "data-bs-placement":
                                                                                                        "bottom",
                                                                                                      title:
                                                                                                        "Edit Segment",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "button",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn btn-none",
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.preventDefault()
                                                                                                    return _vm.deleteSegment(
                                                                                                      segment,
                                                                                                      index,
                                                                                                      i
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "icon",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      src: "/img/icons/dialexa-icons/trash.svg",
                                                                                                      "data-bs-toggle":
                                                                                                        "tooltip",
                                                                                                      "data-bs-placement":
                                                                                                        "bottom",
                                                                                                      title:
                                                                                                        "Delete Segment",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c("div", [
                                                            _c("h6", [
                                                              _vm._v(
                                                                "No segments have been created yet. \n                                                            "
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "/segment/" +
                                                                      _vm.client
                                                                        .url +
                                                                      "/new?dataset=" +
                                                                      dataset.id,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Click here to make a new segment."
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _c("h5", { staticClass: "mt-3" }, [
                      _vm._v(
                        "There are currently no data sets created for this connection."
                      ),
                    ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "preview-modal",
            "aria-labelledby": "preview-modal",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog", class: _vm.modal_size }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header border-bottom-0 pb-0" }, [
                _c("div", { staticClass: "text-center w-100" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-none float-end",
                      attrs: {
                        type: "button",
                        "data-bs-dismiss": "preview-modal",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closePreviewModal()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-close" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-none float-end",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModalSize()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class:
                          _vm.modal_size == "modal-xl"
                            ? "fa-expand"
                            : "fa-compress",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mt-2" }, [
                    _vm._v("Preview File Contents"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body p-0" }, [
                typeof _vm.table_data === "string" && _vm.table_data == ""
                  ? _c("div", { staticClass: "m-2 mb-4 text-center" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("span", { staticClass: "ms-2" }, [
                        _vm._v("Loading..."),
                      ]),
                    ])
                  : Array.isArray(_vm.table_data) && _vm.table_data.length > 0
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "table-responsive gy-0 m-0 mt-0" },
                        [
                          _c("table", { staticClass: "table gy-0 m-0" }, [
                            _c("thead", { staticClass: "table-dark" }, [
                              _c(
                                "tr",
                                _vm._l(
                                  Object.keys(_vm.table_data[0]),
                                  function (row, index) {
                                    return _c(
                                      "th",
                                      {
                                        staticClass: "align-middle pt-0",
                                        class: index == 0 ? "ps-3" : "",
                                      },
                                      [_vm._v(_vm._s(_vm.originalHeader(row)))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              { staticClass: "table-border-bottom-0" },
                              _vm._l(_vm.table_data, function (row) {
                                return _c(
                                  "tr",
                                  _vm._l(row, function (col, index) {
                                    return _c(
                                      "td",
                                      { class: index == 0 ? "ps-3" : "" },
                                      [_vm._v(_vm._s(col))]
                                    )
                                  }),
                                  0
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : Array.isArray(_vm.table_data) && _vm.table_data.length == 0
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "alert alert-warning box-content m-2" },
                        [
                          _vm._v(
                            "\n                            No data was returned.\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "alert alert-danger box-content m-2" },
                        [
                          _vm._v(
                            "\n                            There was an error pulling the data:"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("em", [_vm._v(_vm._s(_vm.table_data))]),
                        ]
                      ),
                    ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("system-modal", {
        attrs: {
          client: _vm.client,
          integrations: [],
          connections: [],
          connection_selected: _vm.connection_selected,
        },
      }),
      _vm._v(" "),
      _c("support-modal", {
        attrs: {
          user: _vm.user,
          client: _vm.client,
          selected_ticket_type: _vm.selected_ticket_type,
          id: "support-modal-" + _vm.selected_ticket_type,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-none p-1",
        attrs: { "data-bs-toggle": "dropdown", "aria-expanded": "false" },
      },
      [
        _c("img", {
          staticClass: "icon pe-0",
          attrs: { src: "/img/icons/dialexa-icons/vertical-dots.svg" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-none p-1",
        attrs: {
          "data-bs-toggle": "dropdown",
          "data-bs-auto-close": "outside",
          "aria-expanded": "false",
        },
      },
      [
        _c("img", {
          staticClass: "icon pe-0",
          attrs: { src: "/img/icons/dialexa-icons/vertical-dots.svg" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("h5", [_vm._v("Can We Help?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Avid AI is built to be as flexible as you need it to be with the ability to customize how channels, appeals, and other critical pieces of information are defined. Unfortunately, with that flexibility comes complexity but we're here to help. Just let us know and we can help you get your data correctly mapped so Avid provides the maximum value."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm text-warning float-left",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }