var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "stickyDiv",
      staticClass: "view-report-benchmark-filter-panel sticky-filter-panel",
    },
    [
      _c("nav", { staticClass: "navbar navbar-expand bg-secondary rounded" }, [
        _c("div", { staticClass: "container-fluid row" }, [
          _c("span", { staticClass: "navbar-brand mb-0 ms-1 h4 col-1" }, [
            _vm._v("Filters:"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse col-11",
              attrs: { id: "benchmark-filter-navbar" },
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav me-auto" },
                [
                  _vm._l(
                    _vm.local_page.dimensions,
                    function (dimension, index) {
                      return _c(
                        "li",
                        { staticClass: "nav-item dropdown me-3 w-25" },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-secondary nav-link dropdown-toggle px-3 w-100",
                              attrs: {
                                href: "#",
                                role: "button",
                                "data-bs-toggle": "dropdown",
                                "data-bs-auto-close":
                                  dimension.column == "period_type"
                                    ? "true"
                                    : "outside",
                                "aria-expanded": "false",
                                disabled:
                                  (dimension.column == "org_vertical" &&
                                    _vm.if_revenue_filter_applied) ||
                                  (dimension.column == "org_annual_revenue" &&
                                    _vm.if_vertical_filter_applied),
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    dimension.column == "period_type"
                                      ? dimension.label +
                                          ": " +
                                          (dimension.value == undefined
                                            ? "Trailing 12 Months"
                                            : dimension.value)
                                      : dimension.label
                                  ) +
                                  "\n\n                            "
                              ),
                              dimension.column == "org_vertical" &&
                              _vm.if_revenue_filter_applied
                                ? _c("i", {
                                    staticClass:
                                      "ms-2 fas fa-info-circle fas-fade",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title:
                                        'Annual Revenue Range filter applied, Click on the "Reset Filters" link to reset all filters and enable the Vertical filter',
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              dimension.column == "org_annual_revenue" &&
                              _vm.if_vertical_filter_applied
                                ? _c("i", {
                                    staticClass:
                                      "ms-2 fas fa-info-circle fas-fade",
                                    attrs: {
                                      "data-bs-toggle": "tooltip",
                                      title:
                                        'Vertical filter applied, Click on the "Reset Filters" link to reset all filters and enable the Annual Revenue Range filter',
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.if_filter_options_loaded &&
                          dimension.field_type == "multi_select"
                            ? _c(
                                "div",
                                { staticClass: "dropdown-menu" },
                                [
                                  _vm._l(dimension.options, function (option) {
                                    return _c(
                                      "label",
                                      {
                                        key: option,
                                        staticClass: "dropdown-item",
                                        on: {
                                          mouseover: function ($event) {
                                            return _vm.setHover(option, true)
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.setHover(option, false)
                                          },
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: dimension.value,
                                              expression: "dimension.value",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: option,
                                            checked: Array.isArray(
                                              dimension.value
                                            )
                                              ? _vm._i(
                                                  dimension.value,
                                                  option
                                                ) > -1
                                              : dimension.value,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = dimension.value,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = option,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        dimension,
                                                        "value",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        dimension,
                                                        "value",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    dimension,
                                                    "value",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.applyFilter(
                                                  index,
                                                  option
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ms-2" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(option) +
                                              " (" +
                                              _vm._s(
                                                _vm.benchmark_filters[
                                                  dimension.id
                                                ].counts[option]
                                              ) +
                                              ") \n                                    "
                                          ),
                                          (dimension.column == "org_vertical" &&
                                            _vm.client.vertical == option) ||
                                          (dimension.column ==
                                            "org_annual_revenue" &&
                                            _vm.client.size == option)
                                            ? _c("i", {
                                                staticClass:
                                                  "ms-1 fa-solid fa-star",
                                              })
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.show_only_button[
                                                    option
                                                  ] &&
                                                  _vm.benchmark_filters[
                                                    dimension.id
                                                  ].counts[option] >= 6,
                                                expression:
                                                  "show_only_button[option] && benchmark_filters[dimension.id].counts[option] >= 6",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-secondary float-end only-selection-btn py-0",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.toggleOnly(
                                                  index,
                                                  option
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("ONLY")]
                                        ),
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  dimension.column == "org_vertical" &&
                                  _vm.client.vertical &&
                                  dimension.org_count >= 6
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-2 mt-2 d-inline-block",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "dropdown-divider",
                                          }),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(
                                              "Note: Include your vertical (marked with star) to see yourself in the benchmark comparison."
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  dimension.column == "org_annual_revenue" &&
                                  _vm.client.size &&
                                  dimension.org_count >= 6
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-2 mt-2 d-inline-block",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "dropdown-divider",
                                          }),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(
                                              "Note: Include your annual revenue (marked with star) to see yourself in the benchmark comparison."
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  dimension.org_count < 6
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger invalid-feedback px-2 mt-2 d-inline-block",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "dropdown-divider",
                                          }),
                                          _vm._v(
                                            "\n                                Your selection would match less than 6 organizations. "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              "For privacy reasons, please select more options."
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.if_filter_options_loaded &&
                          dimension.field_type == "single_select"
                            ? _c(
                                "div",
                                { staticClass: "dropdown-menu" },
                                _vm._l(dimension.options, function (option) {
                                  return _c(
                                    "label",
                                    {
                                      key: option,
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyFilter(index, option)
                                        },
                                      },
                                    },
                                    [
                                      dimension.value == option
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-circle-check text-primary ms-2",
                                          })
                                        : _c("i", { staticClass: "fa ms-4" }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ms-2" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(option) +
                                            "\n                                "
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.if_filter_options_loaded
                            ? _c("div", { staticClass: "dropdown-menu" }, [
                                _vm._m(0, true),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.if_revenue_filter_applied ||
                  _vm.if_vertical_filter_applied
                    ? _c(
                        "a",
                        {
                          staticClass: "mt-2 text-end w-25",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.resetFilterStatus()
                            },
                          },
                        },
                        [_vm._v("Reset Filters")]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "ms-2" }, [
      _c(
        "div",
        {
          staticClass:
            "spinner-border spinner-border-sm text-warning float-left",
          attrs: { role: "status" },
        },
        [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
      ),
      _vm._v("  Loading ...\n                            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }