var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "query-inclusion-builder" },
    [
      _c("div", { staticClass: "row pb-3" }, [
        _c("div", { staticClass: "col-sm-9 mb-2" }, [
          _c(
            "div",
            {
              staticClass:
                "filter-type btn-group toggle-btn-group btn-group-sm d-flex d-sm-inline-flex",
              attrs: { role: "group" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn text-nowrap reg-btn",
                  class: {
                    "btn-primary": _vm.data_filter.filter_option == "all",
                    "btn-white": _vm.data_filter.filter_option != "all",
                    "is-invalid": _vm.errors.logic_statement,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateFilterLogic("all")
                    },
                  },
                },
                [_vm._v("All Filters\n                ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn text-nowrap reg-btn",
                  class: {
                    "btn-primary": _vm.data_filter.filter_option == "any",
                    "btn-white": _vm.data_filter.filter_option != "any",
                    "is-invalid": _vm.errors.logic_statement,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateFilterLogic("any")
                    },
                  },
                },
                [_vm._v("Any Filters\n                ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn text-nowrap reg-btn",
                  class: {
                    "btn-primary no-right-rounded-corner":
                      _vm.data_filter.filter_option == "custom",
                    "btn-white": _vm.data_filter.filter_option != "custom",
                    "is-invalid": _vm.errors.logic_statement,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateFilterLogic("custom")
                    },
                  },
                },
                [_vm._v("Custom Logic\n                ")]
              ),
              _vm._v(" "),
              _vm.data_filter.filter_option == "custom"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data_filter.filter_logic,
                        expression: "data_filter.filter_logic",
                      },
                    ],
                    staticClass:
                      "form-control form-control-sm gx-0 border-start-0",
                    class: { "is-invalid": _vm.errors.logic_statement },
                    staticStyle: {
                      "border-top-left-radius": "0",
                      "border-bottom-left-radius": "0",
                    },
                    attrs: { type: "text" },
                    domProps: { value: _vm.data_filter.filter_logic },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.data_filter,
                          "filter_logic",
                          $event.target.value
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.errors.logic_statement
                ? _c(
                    "div",
                    { staticClass: "invalid-feedback ms-2 text-danger" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.data_filter.logic_error_msg) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col text-sm-end text-center mb-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.addLogicUnit()
                  return false
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-plus" }),
              _vm._v(" Add clause\n            "),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.data_filter.logic_units, function (unit, index) {
        return _c(
          "div",
          {
            staticClass: "row logic-unit",
            class: {
              "is-invalid":
                _vm.hasLogicUnitError(unit) && _vm.errors.logic_units[index],
            },
          },
          [
            _c("div", { staticClass: "col-lg-1 py-1 text-center" }, [
              _c("label", { staticClass: "form-label fs-7 m-0" }, [
                _vm._v("Unit #"),
              ]),
              _vm._v(" "),
              _c("h4", { staticClass: "m-0 mt-1" }, [
                _vm._v(_vm._s(index + 1)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-3 py-1 text-center" },
              [
                _c("label", { staticClass: "form-label fs-7 m-0" }, [
                  _vm._v("Column Name"),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "searchable-select",
                  attrs: {
                    options: _vm.db_columns,
                    searchable: true,
                    selectable: (option) => option.text != "",
                    label: "text",
                    filterable: false,
                  },
                  on: {
                    search: _vm.fetchOptions,
                    input: function ($event) {
                      return _vm.changeDBColumn(unit)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selected-option",
                        fn: function ({ text, category }) {
                          return [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  category != null &&
                                    category != "" &&
                                    text != null &&
                                    text != ""
                                    ? category + ": "
                                    : ""
                                ) +
                                _vm._s(text) +
                                "\n                "
                            ),
                          ]
                        },
                      },
                      {
                        key: "open-indicator",
                        fn: function ({ attributes }) {
                          return [
                            _c(
                              "span",
                              _vm._b(
                                {
                                  staticStyle: {
                                    width: "12px",
                                    "line-height": "8px",
                                  },
                                },
                                "span",
                                attributes,
                                false
                              ),
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 16 16",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#343a40",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        d: "m2 5 6 6 6-6",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "option",
                        fn: function ({ text, category }) {
                          return [
                            text == ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "category-header",
                                    attrs: {
                                      "data-category": category,
                                      "data-isexpanded": "false",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.expandCategory(
                                          category,
                                          null
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(category) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fa-solid fa-caret-right",
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "suboption",
                                    class:
                                      category == null || category == ""
                                        ? "show"
                                        : "",
                                    attrs: { "data-subcategory": category },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(text) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                      {
                        key: "no-options",
                        fn: function ({ search, searching, loading }) {
                          return [
                            _vm.is_loading
                              ? _c("div", { staticClass: "suboption show" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border spinner-border-sm text-warning float-left",
                                      attrs: { role: "status" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "visually-hidden" },
                                        [_vm._v("Loading...")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "  Loading columns\n                    "
                                  ),
                                ])
                              : _c("div", { staticClass: "suboption show" }, [
                                  _c("em", [_vm._v("No results found")]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: unit.db_column,
                    callback: function ($$v) {
                      _vm.$set(unit, "db_column", $$v)
                    },
                    expression: "unit.db_column",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "py-1 text-center",
                class: {
                  "col-lg-3":
                    unit.operator.value != "IS NULL" &&
                    unit.operator.value != "IS NOT NULL",
                  "col-lg-8":
                    _vm.data_filter.logic_units.length <= 1 &&
                    (unit.operator.value == "IS NULL" ||
                      unit.operator.value == "IS NOT NULL"),
                  "col-lg-7 col-9":
                    _vm.data_filter.logic_units.length > 1 &&
                    (unit.operator.value == "IS NULL" ||
                      unit.operator.value == "IS NOT NULL"),
                },
              },
              [
                _c("label", { staticClass: "form-label fs-7 m-0" }, [
                  _vm._v("Operator"),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    options: _vm.operator_options,
                    selectable: (option) => option.text != "",
                    label: "text",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateOperator(unit)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "open-indicator",
                        fn: function ({ attributes }) {
                          return [
                            _c(
                              "span",
                              _vm._b(
                                {
                                  staticStyle: {
                                    width: "12px",
                                    "line-height": "8px",
                                  },
                                },
                                "span",
                                attributes,
                                false
                              ),
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 16 16",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#343a40",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        d: "m2 5 6 6 6-6",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "option",
                        fn: function ({ text, category }) {
                          return [
                            text == ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "category-header",
                                    attrs: {
                                      "data-category": category,
                                      "data-isexpanded": true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.expandCategory(
                                          category,
                                          null
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(category) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fa-solid fa-caret-down",
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "suboption expanded",
                                    attrs: { "data-subcategory": category },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(text) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: unit.operator,
                    callback: function ($$v) {
                      _vm.$set(unit, "operator", $$v)
                    },
                    expression: "unit.operator",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.shouldShowValues(unit.operator)
              ? _c(
                  "div",
                  {
                    staticClass: "py-1 text-center",
                    class: {
                      "col-lg-4 col-9": _vm.data_filter.logic_units.length > 1,
                      "col-lg-5": _vm.data_filter.logic_units.length <= 1,
                    },
                  },
                  [
                    _c("label", { staticClass: "form-label fs-7 m-0" }, [
                      _vm._v("Value"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary dropdown-toggle",
                            attrs: {
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                              disabled: unit.db_column == "",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.typeOfSelection(unit)) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "dropdownMenuButton1" },
                          },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      unit.is_static = false
                                      unit.show_options = false
                                      unit.value = ""
                                    },
                                  },
                                },
                                [_vm._v("Compare to Column")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              unit.data_type != "date"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          unit.is_static = true
                                          unit.show_options = true
                                          unit.value = ""
                                        },
                                      },
                                    },
                                    [_vm._v("List of Options")]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              unit.data_type == "date"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          unit.is_static = false
                                          unit.show_options = true
                                          unit.value = ""
                                        },
                                      },
                                    },
                                    [_vm._v("Date Comparisons")]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      unit.is_static = true
                                      unit.show_options = false
                                      unit.value = ""
                                    },
                                  },
                                },
                                [_vm._v("Static Value")]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        unit.is_static &&
                        unit.show_options == false &&
                        !_vm.isDate(unit.data_type)
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: unit.value,
                                  expression: "unit.value",
                                },
                              ],
                              staticClass: "form-control ps-2",
                              attrs: { type: "text" },
                              domProps: { value: unit.value },
                              on: {
                                change: function ($event) {
                                  return _vm.updateStaticValue(
                                    _vm.data_filter.logic_units[_vm.el]
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(unit, "value", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        unit.is_static &&
                        unit.show_options == false &&
                        _vm.isDate(unit.data_type)
                          ? _c("datepicker", {
                              attrs: {
                                "input-class": "form-control date",
                                placeholder: "MM/DD/YYYY",
                                "bootstrap-styling": true,
                                "use-utc": true,
                                format: "M/d/yyyy",
                              },
                              model: {
                                value: unit.value,
                                callback: function ($$v) {
                                  _vm.$set(unit, "value", $$v)
                                },
                                expression: "unit.value",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        unit.is_static == false && !unit.show_options
                          ? _c("v-select", {
                              staticClass:
                                "searchable-select value_db_select vue_select",
                              attrs: {
                                options: _vm.db_columns,
                                searchable: true,
                                selectable: (option) => option.text != "",
                                label: "text",
                                filterable: false,
                              },
                              on: {
                                search: _vm.fetchOptions,
                                input: function ($event) {
                                  return _vm.changeDBColumn(unit)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "open-indicator",
                                    fn: function ({ attributes }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                width: "12px",
                                                "line-height": "8px",
                                              },
                                            },
                                            "span",
                                            attributes,
                                            false
                                          ),
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 16 16",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    fill: "none",
                                                    stroke: "#343a40",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d: "m2 5 6 6 6-6",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "option",
                                    fn: function ({ text, category }) {
                                      return [
                                        text == ""
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "category-header",
                                                attrs: {
                                                  "data-category": category,
                                                  "data-isexpanded": "false",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.expandCategory(
                                                      category,
                                                      null
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(category) +
                                                    " "
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "fa-solid fa-caret-right",
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "suboption",
                                                class:
                                                  category == null ||
                                                  category == ""
                                                    ? "show"
                                                    : "",
                                                attrs: {
                                                  "data-subcategory": category,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(text) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "no-options",
                                    fn: function ({
                                      search,
                                      searching,
                                      loading,
                                    }) {
                                      return [
                                        _vm.is_loading
                                          ? _c(
                                              "div",
                                              { staticClass: "suboption show" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "spinner-border spinner-border-sm text-warning float-left",
                                                    attrs: { role: "status" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "visually-hidden",
                                                      },
                                                      [_vm._v("Loading...")]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "  Loading columns\n                        "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "suboption show" },
                                              [
                                                _c("em", [
                                                  _vm._v("No results found"),
                                                ]),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: unit.value,
                                callback: function ($$v) {
                                  _vm.$set(unit, "value", $$v)
                                },
                                expression: "unit.value",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        unit.is_static && unit.show_options
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: unit.value,
                                    expression: "unit.value",
                                  },
                                ],
                                staticClass: "form-select",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      unit,
                                      "value",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.db_column_values[unit.db_column.value],
                                function (col) {
                                  return _c(
                                    "option",
                                    { domProps: { value: col.name } },
                                    [_vm._v(_vm._s(col.name))]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !unit.is_static && unit.show_options
                          ? _c("input", {
                              staticClass: "form-control date_num ps-2",
                              attrs: {
                                type: "number",
                                "data-index": index,
                                placeholder: "###",
                              },
                              domProps: { value: _vm.dateNumCalc(unit.value) },
                              on: {
                                change: function ($event) {
                                  return _vm.updateDateValue(index, unit)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !unit.is_static && unit.show_options
                          ? _c(
                              "select",
                              {
                                staticClass:
                                  "form-select input-group-prepend date_comparison",
                                attrs: { "data-index": index },
                                domProps: {
                                  value: _vm.dateComparisonCalc(unit.value),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateDateValue(index, unit)
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "day" } }, [
                                  _vm._v("Days Ago"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "week" } }, [
                                  _vm._v("Weeks Ago"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "month" } }, [
                                  _vm._v("Months Ago"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "year" } }, [
                                  _vm._v("Years Ago"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    unit.operator != null &&
                    (unit.operator.value == "LIKE" ||
                      unit.operator.value == "NOT LIKE" ||
                      unit.operator.value == "IN" ||
                      unit.operator.value == "NOT IN" ||
                      ((unit.operator == "=" ||
                        unit.operator.value == "=" ||
                        unit.operator.value == "<>" ||
                        unit.operator == "<>") &&
                        unit.data_type == "string"))
                      ? _c("div", { staticClass: "form-check" }, [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: unit.modification,
                                  expression: "unit.modification",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", value: "LOWER" },
                              domProps: {
                                checked: Array.isArray(unit.modification)
                                  ? _vm._i(unit.modification, "LOWER") > -1
                                  : unit.modification,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = unit.modification,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "LOWER",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          unit,
                                          "modification",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          unit,
                                          "modification",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(unit, "modification", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "small",
                              {
                                staticStyle: {
                                  "margin-top": "2px",
                                  display: "inline-block",
                                },
                              },
                              [_vm._v("Case-insensitive comparison")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    unit.operator != null &&
                    (unit.operator.value == "IN" ||
                      unit.operator.value == "NOT IN")
                      ? _c("small", [
                          _vm._v(
                            "Comma separate the unique values. Quotes around strings are "
                          ),
                          _c("em", [_vm._v("not")]),
                          _vm._v(" needed."),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data_filter.logic_units.length > 1
              ? _c(
                  "div",
                  {
                    staticClass: "col-lg-1 col-3 py-1",
                    class: {
                      "mt-3":
                        _vm.data_filter.logic_units.length > 1 && index == 0,
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        on: {
                          click: function ($event) {
                            return _vm.removeLogicUnit(index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-trash me-0" })]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasLogicUnitError(unit) && _vm.errors.logic_units[index]
              ? _c(
                  "div",
                  {
                    staticClass: "pb-3 m-0 col-12 invalid-feedback text-center",
                  },
                  [
                    _vm._v(
                      "\n            All of the fields above must be completed.\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }